import React, { useEffect, useState } from 'react'

import Navigation from '../../components/Navigation';

import './Recruit.css';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAuth from '../../hooks/useAuth';
import '../../config/fontAwesome';

function Recruit() {
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const [searchUser, setSearchUser] = useState('');
    const [loading, setLoading] = useState(true);
    const [sortOrder, setSortOrder] = useState('');
    
    useEffect(() => {
        setLoading(true);
        // getUsers();
        // handleDashboardSearch();

        if (location.search) {
            handleDashboardSearch();
        } else {
            getUsers();
        }
    }, []);
    
    // get local user data
    const user = localStorage.getItem('user');
    const empData = JSON.parse(user);

    // get all employee data from database
    const getUsers = async () => {
        try {
            const response = await axiosPrivate.get('/users');
            const userData = response.data;

            const activeEmp = userData.filter(user => user.emp_status === 'Active');
            // make result based on Senior Mangers and Mangers
            // if senior manager logged in it should shown as section_head data
            const sectionHeadRoles = ['Chief Manager', 'AGM'];
            const filterProperty = sectionHeadRoles.includes(empData.user.user_role) ? 'section_head' : 'manager';
            const availableUsers = activeEmp.filter(user => {
                const regex = new RegExp(user[filterProperty], 'i'); // 'i' makes the search case-insensitive
                return regex.test(empData.user.name);
            });
            // display top managment and other users seperatley
            const validUserRoles = ['Chairman', 'Secretary', 'Admin', 'HR', 'MIS', 'Accountant', 'Assistant'];
            validUserRoles?.includes(auth.userRole)
                ? setUsers(activeEmp)
                : setUsers(availableUsers);
        } catch (error) {
            console.error('Error fetching user data : ', error);
        } finally {
            setLoading(false);
        }
    };

    const handleGetUserDetails = (emp_id) => {
        // navigate(`/employee/view?emp_id=${emp_id}`);
        const editUserRoles = ['HR', 'MIS'];
        editUserRoles?.includes(auth.userRole)
        // auth?.userRole === 'HR' || auth?.userRole === 'MIS'
            ? navigate(`/employee/edit?emp_id=${emp_id}`)
            : navigate(`/employee/view?emp_id=${emp_id}`);
    }

    const handleSearchUser = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const regex = new RegExp(searchUser, 'i');
            const searchResult = users.filter(user => 
                regex.test(user.emp_no) || regex.test(user.emp_designation) || regex.test(user.name) || regex.test(user.nic) || regex.test(user.emp_job_section) || user.gender === searchUser
            );
            setUsers(searchResult);
        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            setLoading(false);
        }
    }

    const handleSearchInputChange = (e) => {
        const search = e.target.value;
        setSearchUser(search);
        if (search === '' ) {
            getUsers();
        }
    }

    const handleSortEmpNo = () => {
        const order = sortOrder === 'asc' ? 'desc' : 'asc';
        const sorted = [...users].sort((a, b) => {
          if (order === 'asc') {
            return a.emp_no - b.emp_no;
          } else {
            return b.emp_no - a.emp_no;
          }
        });
        
        setUsers(sorted);
        setSortOrder(order);
    };

    const handleSortDOJ = () => {
        const order = sortOrder === 'asc' ? 'desc' : 'asc';
        const sorted = [...users].sort((a, b) => {
            const dateA = new Date(a.emp_joined_date);
            const dateB = new Date(b.emp_joined_date);

            if (order === 'asc') {
                return dateA - dateB;
            } else {
                return dateB - dateA;
            }
        });
        
        setUsers(sorted);
        setSortOrder(order);
    };

    const handleDashboardSearch = async() => {
        setLoading(true);
        const searchParam = new URLSearchParams(location.search);
        const keyword = searchParam.get('search');
        try {
            const response = await axiosPrivate.get(`/users/${keyword}`);
            const userData = response.data;
            setSearchUser(keyword);
            setUsers(userData);
        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            setLoading(false);
        }
    }

  return (
    <div className='d-flex poisition-relative'>
        <div className='col-2 vh-100 position-fixed'>
            <Navigation />
        </div>
        {/* Header and Search Bar */}
        <div className='col-10 position-absolute top-0 end-0'>
            <div className='px-5 py-3 d-flex justify-content-between'>
                <div className='text-start pt-3'>
                    <div className='fs-4'><span className='bg-green mx-0 px-0 rounded fw-semibold'>&nbsp;Employee&nbsp;</span>Portal,</div>
                    <div className='fs-6 fw-light'>&nbsp;Manage employee data. Add new. Select to edit.</div>
                </div>
                <div className='d-flex align-items-center'>
                    <form onSubmit={handleSearchUser}>
                        <div className='d-flex align-items-center'>
                            <div className='border px-3 rounded responsive-font-sm'>
                                <img src="assets/icon-search.png" alt="search icon" className='pe-3'/>
                                <input type="text" name="search" id="search" className='bg-transparent border-0 py-2 no-border-on-focus' placeholder='Search'
                                    value={searchUser}
                                    onChange={handleSearchInputChange} />
                            </div>
                            <div className='ps-3'>
                                <button className='border rounded py-2 px-4 btn-search shadow-sm btn-add responsive-font-sm'>Search</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            {/* Body context */}
            <div className='mt-1 px-5 mb-5'>
                <div className='d-flex flex-row-reverse'>
                    <Link to={'/employee/add'}>
                        <button type="button" className='border-0 rounded py-2 px-4 bg-green btn-next responsive-font-sm shadow-sm'>Add New</button>
                    </Link>
                </div>
                <div className='mt-3 border border-bottom-0 rounded-3 h-auto'>
                    <table className="table table-striped responsive-font-sm text-reset">
                        <thead>
                            <tr className='text-center'>
                                <th className='border-end'>S/N</th>
                                <th className='border-end' onClick={handleSortEmpNo} role='button'>EMP No</th>
                                <th className='border-end'>Employee Name</th>
                                <th className='border-end' onClick={handleSortDOJ} role='button'>Date of Join</th>
                                <th className='border-end'>NIC</th>
                                <th className='border-end'>Section</th>
                                <th className='border-end'>Designtion</th>
                                <th className='border-end'>Work Type</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            { loading? (
                                <tr className='border w-100'>
                                    <td colSpan={9}><FontAwesomeIcon icon="spinner" style={{ color: '#000' }} className='ms-3 fs-6' spin /></td>
                                </tr>
                            ) : (
                                Array.isArray(users) && users.length > 0 ? (
                                    users.map((user, index) => (
                                        <tr
                                            key={user.user_id}
                                            onClick={() => handleGetUserDetails(user.user_id)}
                                            role='button'
                                            className={`w-100 ${user.emp_status === 'Resign' || user.emp_status === 'Absconded' ? 'text-color-red' : 'text-reset'}`}>
                                            <td className='border-end'>{index + 1}</td>
                                            <td className='border-end'>{user.emp_no}</td>
                                            <td className='border-end text-start'>{user.name}</td>
                                            <td className='border-end' style={{width: '120px'}}>{user.emp_joined_date?.slice(0, 10) || 'NA'}</td>
                                            <td className='border-end'>{user.nic}</td>
                                            <td className='border-end text-start'>{user.emp_job_section}</td>
                                            <td className='border-end text-start'>{user.emp_designation}</td>
                                            <td className='border-end text-start'>{user.emp_work_type}</td>
                                            <td>{user.emp_status}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="9">No users found.</td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Recruit;
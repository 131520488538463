import React, { useEffect, useState } from 'react'
import Navigation from '../../components/Navigation'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate } from 'react-router-dom';

function AddEmployee() {
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    const [lastEmpNo, setLastEmpNo] = useState('');
    const [newEmpNo, setNewEmpNo] = useState('');

    const [ empManagementList, setEmpManagementList ] = useState([]);

    useEffect (() => {
        const getLastEmpNo = async() => {
            try {
                const response = await axiosPrivate.get('/users');
                const users = response.data;
            
                if (!Array.isArray(users) || users.length === 0) {
                    // If no users exist, start with 2600
                    return '2600';
                  }

                // Separate custom numbers and regular numbers
                const customNumbers = users.map(user => parseInt(user.emp_no, 10)).filter(num => !isNaN(num) && num > 9999);
                const regularNumbers = users.map(user => parseInt(user.emp_no, 10)).filter(num => !isNaN(num) && num < 9999);

                // Find the maximum numbers in both categories
                const maxCustomNumber = customNumbers.length > 0 ? Math.max(...customNumbers) : 9999;
                const maxRegularNumber = regularNumbers.length > 0 ? Math.max(...regularNumbers) : 2599;

                // Generate the next regular employee number
                const newEmpNumber = Math.max(maxRegularNumber + 1, 2600);
                setLastEmpNo(newEmpNumber);
                // console.log('new emp no : ', newEmpNumber);

                const formattedEmpNumber = 'EMP' + String(newEmpNumber).padStart(5, '0');
                setNewEmpNo(formattedEmpNumber);
                // console.log('formatted emp no : ', formattedEmpNumber);

                const managementRoles = ['Secretary', 'Admin', 'HR', 'MIS', 'AGM', 'Chief Manager', 'Senior Manager', 'Manager', 'Accountant', 'Assistant'];
                const managementList = users.filter(emp => managementRoles?.includes(emp.user_role));

                setEmpManagementList([...new Set(managementList.map(user => user.name))]);

                return formattedEmpNumber;
            } catch (error) {
                console.error('Error fetching user data:', error);
                return '2600'; // Default to 2600 in case of error
            }
        }

        getLastEmpNo();
    }, []);

    // personal details
    const [empName, setEmpName] = useState('');
    const [empNIC, setEmpNIC] = useState('');
    const [empPermAddress, setEmpPermAddress] = useState('');
    const [empResdAddress, setEmpRedsAddress] = useState('');
    const [empDOB, setEmpDOB] = useState('');
    const [empGender, setEmpGender] = useState('');
    const [empReligion, setEmpReligion] = useState('');
    const [maritalStatus, setMaritalStatus] = useState('');
    const [empContact, setEmpContact] = useState('');
    const [empEmgContact, setEmpEmgContact] = useState('');
    const [empEmgRelation, setEmpEmgRelation] = useState('');

    // employee details
    const [empStatus, setEmpStatus] = useState('');
    const [empNo, setEmpNo] = useState('');
    const [empSection, setEmpSection] = useState('');
    const [empWorkType, setEmpWorkType] = useState('');
    const [empDesignation, setEmpDesignation] = useState('');
    const [empWorkArea, setEmpWorkArea] = useState('');
    const [empJoinDate, setEmpJoinDate] = useState('');
    const [empPayrollId, setEmpPayrollId] = useState('');
    const [empSalary, setEmpSalary] = useState('');
    const [empOfficeNo, setEmpOfficeNo] = useState('');
    const [empSectionHead, setEmpSectionHead] = useState('');
    const [empManager, setEmpManager] = useState('');

    // document details
    const [empCVDoc, setEmpCVDoc] = useState('');
    const [empCVStatus, setEmpCVStatus] = useState(false);
    const [empGSDoc, setEmpGSDoc] = useState('');
    const [empGSStatus, setEmpGSStatus] = useState(false);
    const [empBirthDoc, setEmpBirthDoc] = useState('');
    const [empBirthStatus, setEmpBirthStatus] = useState(false);
    const [empNICDoc, setNICDoc] = useState('');
    const [empNICStatus, setNICStatus] = useState(false);
    const [empDrivingLicense, setDrivingLicense] = useState('');
    const [empDrivingStatus, setDrivingStatus] = useState(false);
    const [empEduDoc, setEmpEduDoc] = useState('');
    const [empEduStatus, setEmpEduStatus] = useState(false);
    const [empCharDoc, setEmpCharDoc] = useState('');
    const [empCharStatus, setEmpCharStatus] = useState(false);
    const [empServiceLetter, setServiceLetter] = useState('');
    const [empServiceStatus, setServiceStatus] = useState(false);
    const [empVehicleRegistration, setVehicleRegistration] = useState('');
    const [empVehicleStatus, setVehicleStatus] = useState(false);
    const [empBankDetails, setBankDetails] = useState('');
    const [empBankStatus, setBankStatus] = useState(false);

    const handleRegisterUser = async (e) => {
        e.preventDefault();
        
        const generateUserRole = (empDesignation) => {
            const rolesMapping = {
                'Chairman': 'Chairman',
                'Secretary': 'Secretary',
                'Admin': 'Admin',
                'HR': 'HR',
                'MIS': 'MIS',
                'AGM': 'AGM',
                'Chief Manager': 'Chief Manager',
                'Senior Manager': 'Senior Manager',
                'Manager': 'Manager',
                'Accountant': 'Accountant',
                'Recovery Officer': 'Recovery Officer',
                'Coordinator': 'Coordinator',
                'Staff': 'Staff'
            };
        
            for (const role in rolesMapping) {
                if (empDesignation.toLowerCase().includes(role.toLowerCase())) {
                    return rolesMapping[role];
                }
            }
        
            return 'N/A';
        };
        const userRole = generateUserRole(empDesignation);

        console.log('new after submit emp no : ', newEmpNo);

        const newUser = {
            'user_id': newEmpNo,
            'user_password': 'test#123',
            'user_role': userRole,
            'name' : empName,
            'nic' : empNIC,
            'gender' : empGender,
            'religion' : empReligion,
            'marital_status' : maritalStatus,
            'perm_address' : empPermAddress,
            'res_address' : empResdAddress,
            'personal_contact' : empContact,
            'dob' : empDOB,
            'emg_contact' : empEmgContact,
            'emg_relation' : empEmgRelation,
            'emp_status' : empStatus,
            'emp_no' : lastEmpNo,
            'emp_job_section' : empSection,
            'emp_work_type' : empWorkType,
            'emp_designation' : empDesignation,
            'emp_work_area' : empWorkArea,
            'emp_joined_date' : empJoinDate,
            'emp_payroll_id' : empPayrollId,
            'emp_salary' : empSalary,
            'emp_office_no': empOfficeNo,
            'section_head' : empSectionHead,
            'manager' : empManager,
            'emp_cv_doc': empCVDoc,
            'emp_cv_status': empCVStatus,
            'emp_gs_doc': empGSDoc,
            'emp_gs_status': empGSStatus,
            'emp_birth_certificate' : empBirthDoc,
            'emp_birth_status' : empBirthStatus,
            'emp_nic_copy' : empNICDoc,
            'emp_nic_status' : empNICStatus,
            'emp_driving_license' : empDrivingLicense,
            'emp_driving_status' : empDrivingStatus,
            'emp_edu_certificate' : empEduDoc,
            'emp_edu_status' : empEduStatus,
            'emp_char_certificate' : empCharDoc,
            'emp_char_status' : empCharStatus,
            'emp_service_letter' : empServiceLetter,
            'emp_service_status' : empServiceStatus,
            'emp_vehicle_registration' : empVehicleRegistration,
            'emp_vehicle_status' : empVehicleStatus,
            'emp_bank_details' : empBankDetails,
            'emp_bank_status' : empBankStatus,
        };

        try {
            const response = await axiosPrivate.post('/users', newUser);
            // reset form
            setEmpName('');
            setEmpNIC('');
            setEmpPermAddress('');
            setEmpRedsAddress('');
            setEmpDOB('');
            setEmpGender('');
            setEmpReligion('');
            setMaritalStatus('');
            setEmpContact('');
            setEmpEmgContact('');
            setEmpEmgRelation('');
            setEmpStatus('');
            setEmpNo('');
            setEmpSection('');
            setEmpWorkType('');
            setEmpDesignation('');
            setEmpWorkArea('');
            setEmpJoinDate('');
            setEmpPayrollId('');
            setEmpSalary('');
            setEmpOfficeNo('');
            setEmpSectionHead('');
            setEmpManager('');
            setEmpCVDoc('');
            setEmpCVStatus(false);
            setEmpGSDoc('');
            setEmpGSStatus(false);
            setEmpBirthDoc('');
            setEmpBirthStatus(false);
            setNICDoc('');
            setNICStatus(false);
            setDrivingLicense('');
            setDrivingStatus(false);
            setEmpEduDoc('');
            setEmpEduStatus(false);
            setEmpCharDoc('');
            setEmpCharStatus(false);
            setServiceLetter('');
            setServiceStatus(false);
            setVehicleRegistration('');
            setVehicleStatus(false);
            setBankDetails('');
            setBankStatus(false);
            alert('Employee added succefully!');
            navigate('/employee');
        } catch (error) {
            console.error('Error creating user:', error);
        }
    }
  return (
    <div className='d-flex poisition-relative'>
        <div className='col-2 vh-100 position-fixed'>
            <Navigation />
        </div>
        {/* Header and Search Bar */}
        <div className='col-10 position-absolute top-0 end-0 bg-light'>
            <div id="header" className='d-flex justify-content-between px-5 py-3'>
                <div className='text-start pt-3'>
                    <div className='fs-4'>Add<span className='bg-green mx-0 px-0 rounded fw-semibold'>&nbsp;New&nbsp;</span>Employee,</div>
                    <div className='fs-6 fw-light'>Fill all required fields to add new employees to the system.</div>
                </div>
            </div>

            {/* Body context */}
            <form onSubmit={handleRegisterUser}>
                <div className='mt-1 px-5 mb-5'>
                    {/* Personal Details */}
                    <div className='row position-relative border-0 rounded-3 mt-4 p-3 py-4 bg-white responsive-font-sm shadow-sm'>
                        <div className='position-absolute top-0 translate-middle mb-5 fs-6 fw-semibold text-start bg-transparent w-auto' style={{left: '10%'}}>Personal Details</div>
                        <div className="d-flex flex-column gap-3 col-6">
                            <div className='text-start'>
                                <label htmlFor="empName">Employee Name</label>
                                <input type="text" name="emp-name" id="empName"
                                    className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                    placeholder='Nimal Perera'
                                    value={empName}
                                    onChange={(e) => setEmpName(e.target.value)}
                                    required/>
                            </div>
                            <div className='text-start'>
                                <label htmlFor="empNIC">NIC Number</label>
                                <input type="text" name="emp-nic" id="empNIC"
                                    className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                    placeholder='198963269854'
                                    value={empNIC}
                                    onChange={(e) => setEmpNIC(e.target.value)}
                                    required/>
                            </div>
                            <div className='text-start'>
                                <label htmlFor="empPermAddress">Permanent Address</label>
                                <input type="text" name="emp-per-address" id="empPermAddress"
                                    className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                    placeholder='No. 10, Flower Road, Colombo'
                                    value={empPermAddress}
                                    onChange={(e) => setEmpPermAddress(e.target.value)}
                                    required/>
                            </div>
                            <div className='text-start'>
                                <label htmlFor="empResdAddress">Residential Address</label>
                                <input type="text" name="emp-resd-address" id="empResdAddress"
                                    className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                    placeholder='No. 10, Flower Road, Colombo'
                                    value={empResdAddress}
                                    onChange={(e) => setEmpRedsAddress(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col-6 d-flex flex-column gap-3">
                            <div className='d-flex row'>
                                <div className='col-6 text-start'>
                                    <label htmlFor="empDOB">Date of Birth</label>
                                    <input type="date" name="emp-dob" id="empDOB"
                                        className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                        placeholder='25/12/1995'
                                        value={empDOB}
                                        onChange={(e) => setEmpDOB(e.target.value)}
                                        required/>
                                </div>
                                <div className='col-6 text-start'>
                                    <label htmlFor="gender">Gender</label>
                                    <select name="gender" id="gender" className='w-100 mt-1 py-2 ps-3 rounded border border-light-subtle form-select'
                                        style={{fontSize: '0.9rem'}}
                                        value={empGender}
                                        onChange={(e) => setEmpGender(e.target.value)}>
                                        <option value="NA">Select</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                            </div>
                            <div className='d-flex row'>
                                <div className='col-6 text-start'>
                                    <label htmlFor="religious">Religion</label>
                                    <select name="religion" id="religion" className='w-100 mt-1 py-2 ps-3 rounded border border-light-subtle form-select'
                                        style={{fontSize: '0.9rem'}}
                                        value={empReligion}
                                        onChange={(e) => setEmpReligion(e.target.value)}>
                                        <option value="NA">Select</option>
                                        <option value="Buddhist">Buddhist</option>
                                        <option value="Christian">Christian</option>
                                        <option value="Catholic">Catholic</option>
                                        <option value="Islam">Islam</option>
                                        <option value="Hindu">Hindu</option>
                                    </select>
                                </div>
                                <div className='col-6 text-start'>
                                    <label htmlFor="maritalStatus">Marital Status</label>
                                    <select name="marital-status" id="maritalStatus" className='w-100 mt-1 py-2 ps-3 rounded border border-light-subtle form-select'
                                        style={{fontSize: '0.9rem'}}
                                        value={maritalStatus}
                                        onChange={(e) => setMaritalStatus(e.target.value)}>
                                        <option value="NA">Select</option>
                                        <option value="Single">Single</option>
                                        <option value="Married">Married</option>
                                        <option value="Divorced">Divorced</option>
                                    </select>
                                </div>
                            </div>
                            <div className='text-start'>
                                <label htmlFor="empContact">Personal Contact</label>
                                <input type="text" name="emp-contact" id="empContact"
                                    className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                    placeholder='0712-365-956'
                                    value={empContact}
                                    onChange={(e) => setEmpContact(e.target.value)}
                                    required/>
                            </div>
                            <div className='row'>
                                <div className='col-6 text-start'>
                                    <label htmlFor="empEmgContact">Emergency Contact</label>
                                    <input type="text" name="emp-emg-contact" id="empEmgContact"
                                        className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                        placeholder='0715-856-965'
                                        value={empEmgContact}
                                        onChange={(e) => setEmpEmgContact(e.target.value)}/>
                                </div>
                                <div className='col-6 text-start'>
                                    <label htmlFor="empEmgRelation">Emergency Contact Relation</label>
                                    <input type="text" name="emp-emg-relation" id="empEmgRelation"
                                        className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                        placeholder='Mother'
                                        value={empEmgRelation}
                                        onChange={(e) => setEmpEmgRelation(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-5 row position-relative border-0 rounded-3 p-3 py-4 bg-white responsive-font-sm shadow-sm'>
                        <div className='position-absolute top-0 translate-middle mb-3 fs-6 fw-semibold text-start bg-transparent w-auto' style={{left: '10%'}}>Employee Details</div>
                        <div className="row">
                            <div className="col-6 d-flex flex-column gap-3 col-6">
                                <div className='row'>
                                    <div className='col-6 text-start'>
                                        <label htmlFor="empStatus">Employee Status</label>
                                        <select name="emp-status" id="empStatus" className='w-100 mt-1 py-2 ps-3 rounded border border-light-subtle form-select'
                                            style={{fontSize: '0.9rem'}}
                                            value={empStatus}
                                            onChange={(e) => setEmpStatus(e.target.value)}
                                            required>
                                            <option value="NA">Select</option>
                                            <option value="Active">Active</option>
                                            <option value="Resign">Resign</option>
                                            <option value="Absconded">Absconded</option>
                                        </select>
                                    </div>
                                    <div className='col-6 text-start'>
                                        <label htmlFor="empNo">Employee Number</label>
                                        <input type="number" name="emp-no" id="empNo"
                                            className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                            placeholder='265' disabled
                                            value={lastEmpNo}
                                            onChange={(e) => setEmpNo(e.target.value)}/>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-6 text-start'>
                                        <label htmlFor="empSection">Employee Section</label>
                                        <select name="emp-section" id="empSection" className='w-100 mt-1 py-2 ps-3 rounded border border-light-subtle form-select'
                                            style={{fontSize: '0.9rem'}}
                                            value={empSection}
                                            onChange={(e) => setEmpSection(e.target.value)}
                                            required>
                                            <option value="NA">Select</option>
                                            <option value="Accountant">Accountant</option>
                                            <option value="Admin">Admin</option>
                                            <option value="Bank">Bank</option>
                                            <option value="Branch">Branch</option>
                                            <option value="Dialog">Dialog</option>
                                            <option value="HR">HR</option>
                                            <option value="IT">IT</option>
                                            <option value="MIS">MIS</option>
                                            <option value="RR">RR</option>
                                            <option value="Sampath Bank">Sampath Bank</option>
                                        </select>
                                    </div>  
                                    <div className='col-6 text-start'>
                                        <label htmlFor="workType">Work Type</label>
                                        <select name="emp-type" id="workType" className='w-100 mt-1 py-2 ps-3 rounded border border-light-subtle form-select'
                                            style={{fontSize: '0.9rem'}}
                                            value={empWorkType}
                                            onChange={(e) => setEmpWorkType(e.target.value)}>
                                            <option value="NA">Select</option>
                                            <option value="Full Time">Full Time</option>
                                            <option value="Part Time">Part Time</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='text-start'>
                                    <label htmlFor="empDesignation">Designation</label>
                                    <input type="text" name="emp-designation" id="empDesignation"
                                        className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                        placeholder='Recovery Officer'
                                        value={empDesignation}
                                        onChange={(e) => setEmpDesignation(e.target.value)}
                                        required/>
                                </div>
                                <div className='text-start'>
                                    <label htmlFor="workArea">Working Area R/O</label>
                                    <input type="text" name="emp-work-area" id="workArea"
                                        className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                        placeholder='Colombo'
                                        value={empWorkArea}
                                        onChange={(e) => setEmpWorkArea(e.target.value)}/>
                                </div>
                            </div>
                            <div className="col-6 d-flex flex-column gap-3 col-6">
                                <div className='text-start'>
                                    <label htmlFor="officePhoneNo">Office Phone Number</label>
                                    <input type="text" name="office-phone-no" id="officePhoneNo"
                                        className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                        placeholder='07XX-XXX-XXX'
                                        value={empOfficeNo}
                                        onChange={(e) => setEmpOfficeNo(e.target.value)}/>
                                </div>
                                <div className='row'>
                                    <div className='col-6 text-start'>
                                        <label htmlFor="empJoinDate">Joined Date</label>
                                        <input type="date" name="emp-joined-date" id="empJoinDate"
                                            className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                            placeholder=''
                                            value={empJoinDate}
                                            onChange={(e) => setEmpJoinDate(e.target.value)}/>
                                    </div>  
                                    <div className='col-6 text-start'>
                                        <label htmlFor="payrollID">Payroll ID</label>
                                        <input type="text" name="payroll-no" id="payrollID"
                                            className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                            placeholder='650'
                                            value={empPayrollId}
                                            onChange={(e) => setEmpPayrollId(e.target.value)}
                                            required/>
                                    </div>
                                </div>
                                <div className='text-start'>
                                    <label htmlFor="salary">Employee Salary (Rs.)</label>
                                    <input type="text" name="salary" id="salary"
                                        className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                        placeholder='50,000'
                                        value={empSalary}
                                        onChange={(e) => setEmpSalary(e.target.value)}/>
                                </div>
                                <div className='row'>
                                    <div className='col-6 text-start'>
                                        <label htmlFor="sectionHead">Employee Section Head</label>
                                        {/* <input type="text" name="emp-work-area" id="workArea"
                                            className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                            placeholder='Samantha'
                                            value={empSectionHead}
                                            onChange={(e) => setEmpSectionHead(e.target.value)}
                                            required/> */}
                                        <select name="sectionHead" id="sectionHead" className='form-select mt-1 py-2 ps-3 rounded border border-light-subtle' style={{fontSize: '0.9rem'}}
                                            value={empSectionHead}
                                            onChange={(e) => setEmpSectionHead(e.target.value)}>
                                            <option value="NA">Select Section Head</option>
                                            { empManagementList.map ((user, index) => (
                                                <option key={index} value={user}>{user}</option>
                                            ))}
                                        </select>
                                    </div>  
                                    <div className='col-6 text-start'>
                                        <label htmlFor="manager">Manager</label>
                                        {/* <input type="text" name="manager" id="manager"
                                            className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                            placeholder='Samantha'
                                            value={empManager}
                                            onChange={(e) => setEmpManager(e.target.value)}
                                            required/> */}
                                        <select name="manager" id="manager" className='form-select mt-1 py-2 ps-3 rounded border border-light-subtle' style={{fontSize: '0.9rem'}}
                                            value={empManager}
                                            onChange={(e) => setEmpManager(e.target.value)}>
                                            <option value="NA">Select Manager</option>
                                            { empManagementList.map ((user, index) => (
                                                <option key={index} value={user}>{user}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* document submission */}
                        <div className="row">
                            <div className='text-start mt-4 mb-3'>Document Submission</div>
                            <div className="col-6 d-flex flex-column gap-3">
                                <div className='d-flex border justify-content-between p-3 rounded-3'>
                                    <div>CV Document</div>
                                    <div className='d-flex justify-content-center'>
                                        <div className='me-4'>
                                            <input type="radio" name="cv" id="cvYes" value="Yes" 
                                                checked={empCVDoc === 'Yes'} 
                                                onChange={(e) => setEmpCVDoc(e.target.value)} />
                                            <label htmlFor="cvYes" className='ms-2'>Yes</label>
                                        </div>
                                        <div>
                                            <input type="radio" name="cv" id="cvNo" value="No"
                                                checked={empCVDoc === 'No'} 
                                                onChange={(e) => setEmpCVDoc(e.target.value)} />
                                            <label htmlFor="cvNo" className='ms-2'>No</label>
                                        </div>
                                        <div className='ms-4 d-flex align-items-center'>
                                            <input type="checkbox" name="cv-check" id="cvCheck"
                                                checked={empCVStatus}
                                                onChange={(e) => setEmpCVStatus(e.target.checked)} />
                                            <label htmlFor="cv-check" className='ms-1'>Original</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex border justify-content-between p-3 rounded-3'>
                                    <div>GS Document</div>
                                    <div className='d-flex'>
                                        <div className='me-4'>
                                            <input type="radio" name="gs" id="gsYes" value="Yes"
                                                checked={empGSDoc === 'Yes'} 
                                                onChange={(e) => setEmpGSDoc(e.target.value)} />
                                            <label htmlFor="gsYes" className='ms-2'>Yes</label>
                                        </div>
                                        <div>
                                            <input type="radio" name="gs" id="gsNo" value="No"
                                                checked={empGSDoc === 'No'} 
                                                onChange={(e) => setEmpGSDoc(e.target.value)} />
                                            <label htmlFor="gsNo" className='ms-2'>No</label>
                                        </div>
                                        <div className='ms-4 d-flex align-items-center'>
                                            <input type="checkbox" name="gs-check" id="gsCheck"
                                                checked={empGSStatus}
                                                onChange={(e) => setEmpGSStatus(e.target.checked)} />
                                            <label htmlFor="gs-check" className='ms-1'>Original</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex border justify-content-between p-3 rounded-3'>
                                    <div>Birth Certificate</div>
                                    <div className='d-flex'>
                                        <div className='me-4'>
                                            <input type="radio" name="birth-doc" id="birthYes" value="Yes"
                                                checked={empBirthDoc === 'Yes'} 
                                                onChange={(e) => setEmpBirthDoc(e.target.value)} />
                                            <label htmlFor="birthYes" className='ms-2'>Yes</label>
                                        </div>
                                        <div>
                                            <input type="radio" name="birth-doc" id="birthNo" value="No" 
                                                checked={empBirthDoc === 'No'} 
                                                onChange={(e) => setEmpBirthDoc(e.target.value)}/>
                                            <label htmlFor="birthNo" className='ms-2'>No</label>
                                        </div>
                                        <div className='ms-4 d-flex align-items-center'>
                                            <input type="checkbox" name="birth-check" id="birthCheck"
                                                checked={empBirthStatus}
                                                onChange={(e) => setEmpBirthStatus(e.target.checked)} />
                                            <label htmlFor="birth-check" className='ms-1'>Original</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex border justify-content-between p-3 rounded-3'>
                                    <div>NIC Copy</div>
                                    <div className='d-flex'>
                                        <div className='me-4'>
                                            <input type="radio" name="nic-doc" id="nicYes" value="Yes"
                                                checked={empNICDoc === 'Yes'} 
                                                onChange={(e) => setNICDoc(e.target.value)} />
                                            <label htmlFor="nicYes" className='ms-2'>Yes</label>
                                        </div>
                                        <div>
                                            <input type="radio" name="nic-doc" id="nicNo" value="No"
                                                checked={empNICDoc === 'No'} 
                                                onChange={(e) => setNICDoc(e.target.value)} />
                                            <label htmlFor="nicNo" className='ms-2'>No</label>
                                        </div>
                                        <div className='ms-4 d-flex align-items-center'>
                                            <input type="checkbox" name="nic-check" id="nicCheck"
                                                checked={empNICStatus}
                                                onChange={(e) => setNICStatus(e.target.checked)} />
                                            <label htmlFor="nic-check" className='ms-1'>Original</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex border justify-content-between p-3 rounded-3'>
                                    <div>Vehicle Registration</div>
                                    <div className='d-flex'>
                                        <div className='me-4'>
                                            <input type="radio" name="vehicle-doc" id="vehicleYes" value="Yes"
                                                checked={empVehicleRegistration === 'Yes'} 
                                                onChange={(e) => setVehicleRegistration(e.target.value)} />
                                            <label htmlFor="vehicleYes" className='ms-2'>Yes</label>
                                        </div>
                                        <div>
                                            <input type="radio" name="vehicle-doc" id="vehicleNo" value="No"
                                                checked={empVehicleRegistration === 'No'} 
                                                onChange={(e) => setVehicleRegistration(e.target.value)} />
                                            <label htmlFor="vehicleNo" className='ms-2'>No</label>
                                        </div>
                                        <div className='ms-4 d-flex align-items-center'>
                                            <input type="checkbox" name="vehicle-check" id="vehicleCheck"
                                                checked={empVehicleStatus}
                                                onChange={(e) => setVehicleStatus(e.target.checked)} />
                                            <label htmlFor="vehicleCheck" className='ms-1'>Original</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 d-flex flex-column gap-3">
                                <div className='d-flex border justify-content-between p-3 rounded-3'>
                                    <div>Driving License</div>
                                    <div className='d-flex'>
                                        <div className='me-4'>
                                            <input type="radio" name="dl-doc" id="dlYes" value="Yes"
                                                checked={empDrivingLicense === 'Yes'} 
                                                onChange={(e) => setDrivingLicense(e.target.value)} />
                                            <label htmlFor="dlYes" className='ms-2'>Yes</label>
                                        </div>
                                        <div>
                                            <input type="radio" name="dl-doc" id="dlNo" value="No"
                                                checked={empDrivingLicense === 'No'} 
                                                onChange={(e) => setDrivingLicense(e.target.value)}  />
                                            <label htmlFor="dlNo" className='ms-2'>No</label>
                                        </div>
                                        <div className='ms-4 d-flex align-items-center'>
                                            <input type="checkbox" name="dl-check" id="dlCheck"
                                                checked={empDrivingStatus}
                                                onChange={(e) => setDrivingStatus(e.target.checked)} />
                                            <label htmlFor="dl-check" className='ms-1'>Original</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex border justify-content-between p-3 rounded-3'>
                                    <div>Education Certificate</div>
                                    <div className='d-flex'>
                                        <div className='me-4'>
                                            <input type="radio" name="edu-doc" id="eduYes" value="Yes"
                                                checked={empEduDoc === 'Yes'} 
                                                onChange={(e) => setEmpEduDoc(e.target.value)} />
                                            <label htmlFor="eduYes" className='ms-2'>Yes</label>
                                        </div>
                                        <div>
                                            <input type="radio" name="edu-doc" id="eduNo" value="No"
                                                checked={empEduDoc === 'No'} 
                                                onChange={(e) => setEmpEduDoc(e.target.value)} />
                                            <label htmlFor="eduNo" className='ms-2'>No</label>
                                        </div>
                                        <div className='ms-4 d-flex align-items-center'>
                                            <input type="checkbox" name="edu-check" id="eduCheck"
                                                checked={empEduStatus}
                                                onChange={(e) => setEmpEduStatus(e.target.checked)} />
                                            <label htmlFor="edu-check" className='ms-1'>Original</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex border justify-content-between p-3 rounded-3'>
                                    <div>Character Certificate</div>
                                    <div className='d-flex'>
                                        <div className='me-4'>
                                            <input type="radio" name="char-doc" id="charYes" value="Yes" 
                                                checked={empCharDoc === 'Yes'} 
                                                onChange={(e) => setEmpCharDoc(e.target.value)} />
                                            <label htmlFor="charYes" className='ms-2'>Yes</label>
                                        </div>
                                        <div>
                                            <input type="radio" name="char-doc" id="charNo" value="No" 
                                                checked={empCharDoc === 'No'} 
                                                onChange={(e) => setEmpCharDoc(e.target.value)}/>
                                            <label htmlFor="charNo" className='ms-2'>No</label>
                                        </div>
                                        <div className='ms-4 d-flex align-items-center'>
                                            <input type="checkbox" name="char-check" id="charCheck"
                                                checked={empCharStatus}
                                                onChange={(e) => setEmpCharStatus(e.target.checked)} />
                                            <label htmlFor="char-check" className='ms-1'>Original</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex border justify-content-between p-3 rounded-3'>
                                    <div>Service Letter</div>
                                    <div className='d-flex'>
                                        <div className='me-4'>
                                            <input type="radio" name="service-doc" id="serviceYes" value="Yes" 
                                                checked={empServiceLetter === 'Yes'} 
                                                onChange={(e) => setServiceLetter(e.target.value)}/>
                                            <label htmlFor="serviceYes" className='ms-2'>Yes</label>
                                        </div>
                                        <div>
                                            <input type="radio" name="service-doc" id="serviceNo" value="No" 
                                                checked={empServiceLetter === 'No'} 
                                                onChange={(e) => setServiceLetter(e.target.value)} />
                                            <label htmlFor="serviceNo" className='ms-2'>No</label>
                                        </div>
                                        <div className='ms-4 d-flex align-items-center'>
                                            <input type="checkbox" name="service-check" id="serviceCheck"
                                                checked={empServiceStatus}
                                                onChange={(e) => setServiceStatus(e.target.checked)} />
                                            <label htmlFor="service-check" className='ms-1'>Original</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex border justify-content-between p-3 rounded-3'>
                                    <div>Bank Details</div>
                                    <div className='d-flex'>
                                        <div className='me-4'>
                                            <input type="radio" name="bank-doc" id="bankYes" value="Yes" 
                                                checked={empBankDetails === 'Yes'} 
                                                onChange={(e) => setBankDetails(e.target.value)}/>
                                            <label htmlFor="bankYes" className='ms-2'>Yes</label>
                                        </div>
                                        <div>
                                            <input type="radio" name="bank-doc" id="bankNo" value="No" 
                                                checked={empBankDetails === 'No'} 
                                                onChange={(e) => setBankDetails(e.target.value)} />
                                            <label htmlFor="bankNo" className='ms-2'>No</label>
                                        </div>
                                        <div className='ms-4 d-flex align-items-center'>
                                            <input type="checkbox" name="bank-check" id="bankCheck"
                                                checked={empBankStatus}
                                                onChange={(e) => setBankStatus(e.target.checked)} />
                                            <label htmlFor="bankCheck" className='ms-1'>Original</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* control buttons */}
                    <div className='mt-4 d-flex justify-content-center'>
                        <div className='me-3 w-25'>
                            <button type="submit" className='w-100 p-2 border rounded-3 bg-dark text-light responsive-font-sm'>Submit</button>
                        </div>
                        <div className='w-25'>
                            <button type="reset" className='w-100 p-2 border rounded-3 responsive-font-sm'>Clear</button>
                        </div>
                    </div>
                </div>
            </form>
            <div className='py-3 bg-dark text-center text-light'>
                &copy;SensesHub BPO Services. 2024
            </div>
        </div>
    </div>
  )
}

export default AddEmployee
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../../components/Navigation';
import './Evaluation.css';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../config/fontAwesome';

const Evaluation = () => {
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    // for search employee
    const [searchUser, setSearchUser] = useState('');
    const [ evMonth, setEvMonth ] = useState();
    const [ evYear, setEvYear ] = useState();
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        const user = localStorage.getItem('user');
        const empData = JSON.parse(user);

        const getUsers = async () => {
            try {
                // get employee data from database
                const response = await axiosPrivate.get('/users');
                const userData = response.data;
                // get evaluation data from database
                const evaluationResponse = await axiosPrivate.get('/evaluation');
                const evaluationData = evaluationResponse.data;

                // cheif manager and AGM can see all the employees of their managers
                const sectionHeadRoles = ['Chief Manager', 'AGM'];
                const filterProperty = sectionHeadRoles.includes(empData.user.user_role) ? 'section_head' : 'manager';
                const availableUsers = userData.filter(user => {
                    const regex = new RegExp(user[filterProperty], 'i'); // 'i' makes the search case-insensitive
                    return regex.test(empData.user.name) && user.emp_status === 'Active';
                });
                
                // display all users for the top management
                const validUserRoles = ['Chairman', 'Secretary', 'Admin', 'HR', 'MIS', 'Accountant', 'Assistant'];
                const filteredUsers = validUserRoles.includes(auth.userRole) ? userData : availableUsers;
                // make employee evaluation status to not done by default
                const modifiedUsers = filteredUsers.map(user => ({
                    ...user,
                    isEvaluated: 'Not Done'
                }));

                // filter evaluated employees from all available employees
                const evaluatedUsers = evaluationData.filter(data => (
                    modifiedUsers.find(user => user.user_id === data.emp_no)
                ));

                // get current date, month and year
                const currentDate = new Date();
                const currentMonth = currentDate.getMonth();
                const currentYear = currentDate.getFullYear();
                // check evaluated employees are valid for this month
                const validEvaluations = evaluatedUsers.filter(user => {
                    const evaluateDate = new Date(user.ev_timestamp);
                    const evaluateMonth = evaluateDate.getMonth();
                    const evaluateYear = evaluateDate.getFullYear();
                    //check current month and year are matching
                    const isCurrentMonth = evaluateMonth === currentMonth && evaluateYear === currentYear;

                    return isCurrentMonth;
                });

                // set evaluation month and evaluation year to display in table top
                setEvMonth(currentDate.toLocaleDateString('default', { month: 'long' }));
                setEvYear(currentYear);

                // make evaluated employees status to Done
                const validEvaluatedUsers = modifiedUsers.map(user => {
                    const isEvaluated = validEvaluations.some(data => (
                        user.user_id === data.emp_no && empData.user.user_id === data.user_id
                    )) ? "Done" : "Not Done";
                    return { ...user, isEvaluated };
                });

                setUsers(validEvaluatedUsers);
            } catch (error) {
                console.error('Error : ', error);
            } finally {
                setLoading(false);
            }
        };

        getUsers();

    }, [auth.userRole]);

    const handleGetUserDetails = (emp_id) => {
        const userDetails = users.find(user => user.user_id === emp_id);
        userDetails?.user_role == 'Recovery Officer'
            ? navigate(`/evaluation/form1?emp_id=${emp_id}`)
            : userDetails?.user_role == 'Coordinator'
                ? navigate(`/evaluation/form2?emp_id=${emp_id}`)
                : navigate(`/evaluation/form3?emp_id=${emp_id}`)
    }

    const handleSearchUser = (e) => {
        try {
            const searchResult = users.filter(user => {
                    const regex = new RegExp(searchUser, 'i'); // 'i' makes the search case-insensitive
                    return regex.test(user.user_id) || regex.test(user.name);
            });
            searchResult? setUsers(searchResult) : setUsers(users);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }

    const handleInputChange = (e) => {
        const query = e.target.value;
        setSearchUser(query);
        if (query === '') {
            setUsers(users); // Reset to all users if the input is cleared
        } else {
            handleSearchUser(query);
        }
    };

    const handleAllUsers = async () => {
        auth?.userRole === 'Chairman'
            ? navigate('/evaluation/view-all')
            : navigate('/evaluation/view');
    }

  return (
    <div className='d-flex vh-100'>
            {/* Section: Navigation */}
            <div className='col-2 vh-100 position-fixed'>
                <Navigation />
            </div>

            {/* Section: Dashboard */}
            <div className="col-10 vh-100 position-absolute top-0 end-0 bg-light">
                {/* header */}
                <div id="header" className='d-flex justify-content-between px-5 py-3'>
                    <div className='text-start pt-3'>
                        <div className='fs-4'>
                            Performance<span className='bg-green mx-0 px-0 rounded fw-semibold'>&nbsp;Evaluate&nbsp;</span>,
                        </div>
                        <div className='fs-6 mt-1 fw-light'>Manage Employees data with care!</div>
                    </div>
                </div>

                {/* users */}
                <div className='px-5 py-3'>
                    <div className='mb-4 d-flex rounded position-relative'>
                        {/* <div className='responsive-font-sm fw-semibold position-absolute top-0 translate-middle bg-white px-3' style={{left: '10%'}}>Evaluation Schemas</div> */}
                        <div className='col-5 pe-3 responsive-font-sm'>
                            <div className='fs-6 text-start text-uppercase fw-semibold'>Intructions :</div>
                            <div className='text-start text-secondary'>
                                <ol className='ps-3 lh-base'>
                                    <li>You can use available evaluation forms to perfom employee evaluation.</li>
                                    <li>Please choose correct form upon your user role.</li>
                                    <li>If you need any assistant please try to contact Admin.</li>
                                </ol>
                                <div>Note:</div>
                                <ul className='ps-3 lh-base'>
                                    <li>All the Managers & Higher Management able to evaluate Coordinators and Recovery Officers.</li>
                                    <li>But only Higher Management can evaluate all the Managers.</li>
                                    <li>All the employees under your name listed below. <span className='fw-semibold'>Search</span> any employee and <b className='fw-semibold'>click</b> to start evaluation.</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-7'>
                            <div className="d-flex flex-column gap-1 responsive-font-sm">
                                <Link to="/evaluation/form1" className='h-100 pt-3 pe-1 d-flex align-items-start user-card text-center text-decoration-none text-reset border-0 rounded m-1 col shadow-sm'>
                                    <div className='w-25'><img src="/assets/user-ro.png" alt="icon recovery" className='w-50'/></div>
                                    <div className='text-start'>
                                        <div className='fw-semibold'>Recovery Officers</div>
                                        <div>Managers & AGM able evaluate recovery officers</div>
                                    </div>
                                </Link>
                                <Link to="/evaluation/form2" className='pt-3 user-card d-flex align-items-start text-center text-decoration-none text-reset border-0 rounded m-1 col shadow-sm'>
                                    <div className='w-25'><img src="/assets/user-employee.png" alt="icon recovery" className='w-50'/></div>
                                    <div className='text-start'>
                                        <div className='fw-semibold'>Coordinators & Team Leaders</div>
                                        <div className='pb-2'>Managers able evaluate recovery officers</div>
                                    </div>
                                </Link>
                                <Link to="/evaluation/form3" className='pt-3 d-flex align-items-start user-card text-center text-decoration-none text-reset border-0 rounded m-1 col shadow-sm'>
                                    <div className='w-25'><img src="/assets/user-coordinator.png" alt="icon recovery" className='w-50'/></div>
                                    <div className='text-start'>
                                        <div className='fw-semibold'>Managers & Project Heads</div>
                                        <div className='pb-2'>Only higher management able evaluate recovery officers</div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        
                    </div>
                    <hr />
                    <div className='mt-4'>
                        <div className='mb-2 pb-3'>
                            <div className='d-flex justify-content-between align-items-end'>
                                <div>
                                    <div className='fs-6 fw-semibold text-start text-uppercase'>Search</div>
                                    <div className='responsive-font-sm fw-light'>Enter employee number, name or NIC to search employees.</div>
                                </div>
                                    <div className='d-flex align-items-center responsive-font-sm'>
                                        {/* <form onSubmit={handleSearchUser} className='d-flex'> */}
                                            <div className='border px-3 rounded bg-white'>
                                                <img src="/assets/icon-search.png" alt="search icon" className='pe-3'/>
                                                <input type="text" name="search" id="search" className='bg-white border-0 py-2 no-border-on-focus' placeholder='Search'
                                                value={searchUser}
                                                // onChange={(e) => setSearchUser(e.target.value)}
                                                onChange={handleInputChange}/>
                                            </div>
                                            <div className='ps-3'>
                                                <button className='border-0 rounded py-2 px-4 btn-search bg-white shadow-sm btn-add'
                                                    onClick={handleSearchUser}>Search</button>
                                            </div>
                                        {/* </form> */}
                                        <div className='ps-3'>
                                            <button className='btn-active border-0 rounded py-2 px-4 btn-search shadow-sm btn-add' onClick={handleAllUsers}>View All</button>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div className='my-2 responsive-font-sm text-start'>Current Evaluation Month : <span className='fw-bold'>{evMonth}</span>, {evYear}</div>
                        <div className='border border-bottom-0 rounded'>
                            <table className="table table-striped responsive-font-sm">
                                <thead>
                                    <tr className='text-center'>
                                        <th className='border-end'>S/N</th>
                                        <th className='border-end'>Employee Number</th>
                                        <th className='border-end'>Employee Name</th>
                                        <th className='border-end'>Joined Date</th>
                                        <th className='border-end'>NIC</th>
                                        <th className='border-end'>Section</th>
                                        <th className='border-end'>Designtion</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { loading ? (
                                        <tr className='border text-center w-100'>
                                            <td colSpan={8}><FontAwesomeIcon icon="spinner" style={{ color: '#000' }} className='ms-3 fs-6' spin /></td>
                                        </tr>
                                    ) : (
                                        Array.isArray(users) && users.length > 0 ? (
                                            users.map((user, index) => (
                                                <tr 
                                                    key={user.user_id} 
                                                    // onClick={() => handleGetUserDetails(user.user_id)}
                                                    onClick={() => {
                                                        if (user.isEvaluated === 'Not Done') {
                                                            handleGetUserDetails(user.user_id)   
                                                        } else {
                                                            alert('User already evaluated for the month of ' + evMonth)
                                                        }
                                                    }}
                                                    role='button'>
                                                    <td className='border-end'>{index + 1}</td>
                                                    <td className='border-end'>{user.user_id}</td>
                                                    <td className='border-end text-start'>{user.name}</td>
                                                    <td className='border-end' style={{width: '120px'}}>{user.emp_joined_date?.slice(0, 10) || 'NA'}</td>
                                                    <td className='border-end'>{user.nic}</td>
                                                    <td className='border-end'>{user.emp_job_section}</td>
                                                    <td className='border-end text-start'>{user.emp_designation}</td>
                                                    {/* <td style={{width: '120px'}}>{user.emp_work_type}</td> */}
                                                    <td style={{width: '120px'}}>{user.isEvaluated}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8">No users found.</td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Evaluation
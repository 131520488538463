import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import authService from '../../services/authService';
import useAuth from '../../hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Login.module.css';
import '../../config/fontAwesome';

const Login = () => {
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || '/dashboard';

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [failed, setFailed] = useState(false);

    // Login Function
    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await authService.login(username, password);
            console.log('Login successful!');
            localStorage.setItem('user', JSON.stringify(response)); //store logged user data in local storage
            // set auth data variables
            const accessToken = response.accessToken; // get access token from API response
            const userRole = response.user.user_role; // get user role from API response
            const authData = { username, userRole, accessToken };
            localStorage.setItem('auth', JSON.stringify(authData)); // store auth data in local storage
            // set auth data & clear username and password input fields
            setAuth(authData);
            setUsername('');
            setPassword('');
            console.log('Navigating to:', from); // show where navigating from
            // set timer to show login success status and redirect
            setTimeout(() => {
                setSuccess(true);
                setTimeout(() => {
                    navigate(from, { replace: true });
                    setLoading(false);
                }, 1000)
            }, 1000)
        } catch (error) {
            console.error('Login failed: ', error.response?.data || error.message);
            // set timer to show login failed status
            setTimeout(() => {
                setLoading(false);
                setTimeout(() => {
                    setFailed(true);
                    setSuccess(false);
                })
                setTimeout(() => {
                    setFailed(true);
                    setSuccess(false);
                })
            }, 1000)
        }
    };

    return (
        <div className={`container-fluid vh-100 p-0 d-flex flex-column justify-content-center ${styles.bgLinearGreen}`}>
            {/* <div className='border border-black py-5 d-flex align-items-end'>
                <img src="/images/img_logo.png" alt="Logo" className="mx-auto" width="15%"/>
                <img src="/assets/logo-white.png" alt="logo-white" />
                <div className='ms-2 fs-4 fw-bold'>SensusHub BPO Services</div>
            </div> */}
            <div className="d-flex justify-content-center align-items-center">
                <div className="col-6 d-flex justify-content-center">
                    <img src="/images/img_login.png" alt="Sample" className="img-fluid" />
                </div>
                <div className="col-6 h-100 d-flex flex-column justify-content-center">
                    <div className='w-50'>
                        <div className='mb-5 d-flex flex-column align-items-start'>
                            <img src="/assets/logo-white.png" alt="logo-white" />
                            <div className='mt-2 fs-4 fw-bold'>Sensus BPO Services</div>
                        </div>
                        <div className='mb-4'>
                            <div className="mb-2 text-start fw-semibold fs-5">Login</div>
                            <div className="text-start text-secondary fs-6">Welcome back! Let's get started</div>
                        </div>
                        <div className='mb-3'>
                            <form onSubmit={handleLogin}>
                                <div className="mb-2">
                                    <input 
                                        type="text" 
                                        id="username" 
                                        className="py-2 w-100 rounded ps-3 border"
                                        placeholder="Username"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)} />
                                </div>
                                <div className="mb-4">
                                    <input 
                                        type="password" 
                                        id="password" 
                                        className="py-2 w-100 rounded ps-3 border"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)} />
                                </div>
                                <div className="w-100">
                                    <button type="submit" className={`w-100 border rounded py-2 ${styles.btnLogin}`} disabled={ loading }>
                                        Login
                                        { loading && <FontAwesomeIcon icon="spinner" style={{ color: '#FFF' }} className='ms-3' spin />}
                                    </button>
                                </div>
                            </form>
                            { success && !failed &&
                                <div className='d-flex align-items-center mt-3 text-start ps-4 text-success border rounded border-success-subtle bg-success-subtle p-2'>
                                    <FontAwesomeIcon icon="circle-check" className='me-3 text-success fs-5' />
                                    Login Success
                                </div>
                            }
                            { !success && failed &&
                                <div className='d-flex align-items-center mt-3 text-start ps-4 text-danger border rounded border-danger-subtle bg-danger-subtle p-2'>
                                    <FontAwesomeIcon icon="circle-xmark" className='me-2 text-danger fs-5' />
                                    Incorrect username or password.
                                </div>
                            }
                        </div>
                        <div className="text-start">
                            <a href="/forget-password" className="text-secondary">Forgot Password?</a>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Login;

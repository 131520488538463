import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import Papa from 'papaparse';
import Navigation from '../../components/Navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../config/fontAwesome';

const Report = () => {
    const [users, setUsers] = useState([]);
    const [ filteredUsers, setFilteredUsers ] = useState([]);
    const [ collectionData, setCollectionData ] = useState([]);
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const [searchUser, setSearchUser] = useState('');
    const [filterData, setFilterData] = useState('');
    const [ printData, setPrintData ] = useState('');
    const [superUser, setSuperUser] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        const getUsers = async () => {
            try {
                // get all the evaluation data from db
                const response = await axiosPrivate.get('/evaluation');
                const userData = response.data;

                // set how many evaluations under one employee
                const usersCount = userData.reduce((acc, user) => {
                    if (!acc[user.emp_no]) {
                        // If not, create a new array for this emp_no
                        acc[user.emp_no] = [];
                    }
                    // Push the current record to the emp_no array
                    acc[user.emp_no].push(user);
                    return acc;
                }, {});

                // group multiple evaluations and add into a object
                const updatedUserData = userData.map(user => {
                    return {
                        ...user,
                        groupedRecords: usersCount[user.emp_no] || []
                    };
                });

                // filter evaluation data to show only one record
                const seenEvaluations = new Set();
                const filteredEvaluations = updatedUserData.filter(user => {
                    if (seenEvaluations.has(user.emp_no)) {
                        return false;
                    } else {
                        seenEvaluations.add(user.emp_no);
                        return true;
                    }
                });

                setUsers(filteredEvaluations);
                setFilteredUsers(filteredEvaluations);

                // set restriction of the evaluator details from other users
                auth?.userRole === 'Chairman'
                ? setSuperUser(true)
                : setSuperUser(false);
            } catch (error) {
                console.error('Error: ', error);
            } finally {
                setLoading(false);
            }
        };

        getUsers();
    }, []);

    const handleGetUserDetails = (emp_id) => {
        navigate(`/${emp_id}`);
    }

    const handleSearchUser = async () => {
        // e.preventDefault();
        try {
            const regex = new RegExp(searchUser, 'i');
            const result = users.filter(user => 
                regex.test(user.emp_no) || regex.test(user.emp_name) || regex.test(user.emp_role) || regex.test(user.ev_score_average)
            );
            
            setPrintData(result);
            setUsers(result);
        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            setLoading(false);
        }
    }

    const handleSearchInputChange = (e) => {
        const search = e.target.value;
        setSearchUser(search);
        if (search === '') {
            setUsers(filteredUsers); // Reset to all users if the input is cleared
            // navigate('/report');
        } else {
            handleSearchUser();
        }
    }

    const handleFilterData = async (e) => {
        e.preventDefault();
        const keyword = filterData.trim();
        const regex = new RegExp(keyword, 'i')
        const filteredUsers = users.filter(user => (
        regex.test(user.emp_no) ||
        regex.test(user.emp_name) ||
        regex.test(user.emp_role) ||
        regex.test(user.ev_timestamp) ||
        regex.test(user.ev_score_average)
        ));
        setPrintData(filteredUsers);
        setUsers(filteredUsers);
    }

    const calcAvgScore = (dataset) => {
        return dataset.length > 0
        ? (
            dataset.reduce((total, record) => 
                total + parseFloat(record.ev_score_average || 0), 0
            ) / dataset.length
        ).toFixed(2)
        : 'N/A'
    }

    const handleInputData = async (e) => {
        const file = e.target.files[0];

        if (file) {
            Papa.parse(file, {
                complete: (result) => {
                    setCollectionData(result.data);
                },
                header: true,
                skipEmptyLines: true
            });
        }
    }

    const handleCollectionData = async (e) => {
        e.preventDefault();
        const collectedData = JSON.stringify(collectionData);
        try {
            const response = await axiosPrivate.post('/collection', collectedData);
            alert('Collection data added!');
            window.location.reload();
        } catch (error) {
            console.error('Error adding data:', error);
        }
    }

    const viewCollectionData = async () => {
        navigate('/report/collection-view')
    }

    const convertArrayToCSV = (array) => {
        const keys = Object.keys(array[0]);
        const header = keys.join(",");
        const data = array.map(item => keys.map(key => item[key]).join(",")).join("\n");
        return `${header}\n${data}`;
    };

    const handleDownload = () => {
        if (printData) {
            const csvData = convertArrayToCSV(printData);
            const blob = new Blob([csvData], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'data.csv';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        } else {
            const csvData = convertArrayToCSV(users);
            const blob = new Blob([csvData], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'data.csv';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }
    };

    const handleSort = () => {
        const order = sortOrder === 'asc' ? 'desc' : 'asc';
        const sorted = [...users].sort((a, b) => {
          if (order === 'asc') {
            return a.ev_score_average - b.ev_score_average;
          } else {
            return b.ev_score_average - a.ev_score_average;
          }
        });
        setUsers(sorted);
        setSortOrder(order);
    };

  return (
    <div className='d-flex poisition-relative'>
        <div className='col-2 vh-100 position-fixed'>
            <Navigation />
        </div>
        {/* Header and Search Bar */}
        <div className='col-10 vh-100 position-absolute top-0 end-0 bg-light'>
            <div id="header" className='d-flex justify-content-between px-5 py-3'>
                <div className='text-start pt-3'>
                    <div className='fs-4'>Generate<span className='bg-green mx-0 px-0 rounded fw-semibold'>&nbsp;Reports&nbsp;</span>,</div>
                    <div className='fs-6 fw-light'>Sort out all the necessary data using reports.</div>
                </div>
            </div>

            {/* Body context */}
            <div className='mt-1 px-5 mb-5'>
                <div className='text-start d-flex justify-content-between align-items-center p-3 border rounded-3 responsive-font-sm bg-white'>
                    <div className='fs-6 fw-semibold'>Upload Recovery Officers Collection Data</div>
                    <div className='d-flex align-items-center'>
                        <form onSubmit={handleCollectionData}>
                            <div className='d-flex align-items-center'>
                                {/* <div className='border px-3 rounded'>
                                    <img src="assets/icon-search.png" alt="search icon" className='pe-3'/>
                                    <input type="text" name="search" id="search" className='bg-transparent border-0 py-2 no-border-on-focus' placeholder='Search'
                                        value={searchUser}
                                        onChange={(e) => setSearchUser(e.target.value)} />
                                </div> */}
                                <div>
                                    <input type="file" accept=".csv" name="collection-data" id="collectionData" className='border p-2 rounded-3'
                                        onChange={handleInputData}/>
                                </div>
                                <div className='ps-3'>
                                    <button className='border rounded py-2 px-4 btn-search shadow-sm btn-add'>Upload</button>
                                </div>
                            </div>
                        </form>
                        <div className='ms-3'>
                            <button className='border rounded py-2 px-4 btn-search shadow-sm btn-add btn-active' onClick={viewCollectionData}>View All</button>
                        </div>
                    </div>
                </div>
                <div className='mt-3 text-start px-4 py-3 border rounded-3 bg-white'>
                    <div className='d-flex justify-content-between'>
                        <div>
                            <div className='fs-6 fw-semibold'>Generate reports</div>
                            <div className='responsive-font-sm'>Search employee number, name, role or average score</div>
                        </div>
                        <div className='responsive-font-sm'>
                            {/* <form onSubmit={handleSearchUser}> */}
                                <div className='d-flex align-items-center'>
                                    <div className='border px-3 rounded w-100'>
                                        <img src="assets/icon-search.png" alt="search icon" className='pe-3'/>
                                        <input type="text" name="search" id="search" className='bg-transparent border-0 py-2 no-border-on-focus' placeholder='Search'
                                            value={searchUser}
                                            onChange={handleSearchInputChange} />
                                    </div>
                                    <div className='ps-3'>
                                        <button className='border rounded py-2 px-4 btn-search shadow-sm btn-add' onClick={handleSearchUser}>Search</button>
                                    </div>
                                </div>
                            {/* </form> */}
                            <form onSubmit={handleFilterData}>
                                <div className='d-flex mt-3 align-items-center'>
                                    <div className='border px-3 rounded w-100'>
                                        <input type="text" name="filter" id="filter" className='bg-transparent border-0 py-2 no-border-on-focus' placeholder='Input value'
                                            value={filterData}
                                            onChange={(e) => setFilterData(e.target.value)} />
                                    </div>
                                    <div className='ps-3'>
                                        <button className='border rounded py-2 px-4 btn-search shadow-sm btn-add'>Filters</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* search result */}
                    <div className='mt-3 border rounded-3 overflow-y-auto' style={{maxHeight: '600px'}}>
                        <table className="table table-striped responsive-font-sm">
                            <thead>
                                <tr className='text-center'>
                                    <th className='border-end'>S/N</th>
                                    <th className='border-end'>Employee Number</th>
                                    <th className='border-end'>Employee Name</th>
                                    <th className='border-end'>Employee Role</th>
                                    {/* <th className='border-end'>Evaluation Date</th> */}
                                    {/* <th className='border-end' onClick={handleSort} role='button'>Average Score</th> */}
                                    <th className='border-end'>Rating 1</th>
                                    <th className='border-end'>Rating 2</th>
                                    <th className='border-end'>Rating 3</th>
                                    {/* <th className='border-end'>Total Average</th>
                                    <th className='border-end'>Evaluator ID</th>
                                    <th className=''>Evaluator Name</th> */}
                                    <th className='border-end' onClick={handleSort} role='button'>Average Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                { loading ? (
                                    <tr className='border text-center w-100'>
                                        <td colSpan={8}><FontAwesomeIcon icon="spinner" style={{ color: '#000' }} className='ms-3 fs-6' spin /></td>
                                    </tr>
                                ) : (
                                    Array.isArray(users) && users.length > 0 ? (
                                        users.map((data, index) => (
                                            <tr 
                                                onClick={() => handleGetUserDetails(data.emp_no)}
                                                role='button'>
                                                <td className='border-end'>{index + 1}</td>
                                                <td className='border-end'>{data.emp_no}</td>
                                                <td className='border-end text-start'>{data.emp_name}</td>
                                                <td className='border-end'>{data.emp_role}</td>
                                                <td className='border-end'>
                                                    <div className='pb-2'>{data.groupedRecords[0] ? data.groupedRecords[0].ev_score_average : 'N/A'}</div>
                                                    <div className='py-2 border-top'>{data.groupedRecords[0] ? data.groupedRecords[0].ev_timestamp : 'N/A'}</div>
                                                    <div className='pt-2 border-top'>
                                                    {superUser 
                                                        ? (
                                                            <>
                                                                {data.groupedRecords[0] ? data.groupedRecords[0].user_name : 'N/A'}&nbsp;
                                                                ({data.groupedRecords[0] ? data.groupedRecords[0].user_role : 'N/A'})
                                                            </>
                                                        ) 
                                                        : 'Restricted'}
                                                    </div>
                                                </td>
                                                <td className='border-end'>
                                                    <div className='pb-2'>{data.groupedRecords[1] ? data.groupedRecords[1].ev_score_average : 'N/A'}</div>
                                                    <div className='py-2 border-top'>{data.groupedRecords[1] ? data.groupedRecords[1].ev_timestamp : 'N/A'}</div>
                                                    <div className='pt-2 border-top'>
                                                    {superUser 
                                                        ? (
                                                            <>
                                                                {data.groupedRecords[1] ? data.groupedRecords[1].user_name : 'N/A'}&nbsp;
                                                                ({data.groupedRecords[1] ? data.groupedRecords[1].user_role : 'N/A'})
                                                            </>
                                                        ) 
                                                        : 'Restricted'}
                                                    </div>
                                                </td>
                                                <td className='border-end'>
                                                    <div className='pb-2'>{data.groupedRecords[2] ? data.groupedRecords[2].ev_score_average : 'N/A'}</div>
                                                    <div className='py-2 border-top'>{data.groupedRecords[2] ? data.groupedRecords[2].ev_timestamp : 'N/A'}</div>
                                                    <div className='pt-2 border-top'>
                                                    {superUser 
                                                        ? (
                                                            <>
                                                                {data.groupedRecords[2] ? data.groupedRecords[2].user_name : 'N/A'}&nbsp;
                                                                ({data.groupedRecords[2] ? data.groupedRecords[2].user_role : 'N/A'})
                                                            </>
                                                        ) 
                                                        : 'Restricted'}
                                                    </div>
                                                </td>
                                                <td className='border-end'>
                                                    {calcAvgScore(data.groupedRecords)}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="8">No users found.</td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                        
                    </div>
                    <div className='d-flex justify-content-end pt-3'>
                        <button className='border rounded py-2 px-4 btn-search shadow-sm btn-add btn-active responsive-font-sm' onClick={handleDownload}>Generate</button>
                    </div>
                </div>
            </div>
            {/* <div className='py-3 bg-dark text-center text-light'>
                &copy;SensesHub BPO Services. 2024
            </div> */}
        </div>
    </div>
  )
}

export default Report

import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();

    if (!auth) {
        // Optionally, you can add a loading state or a redirect to a login page
        return <Navigate to='/' state={{ from: location }} replace />;
    }

    // console.log('Auth Data:', auth);

    return (
        allowedRoles.includes(auth?.userRole)
            ? <Outlet />
            : auth.username
                ? <Navigate to='/unauthorized' state={{ from: location }} replace />
                : <Navigate to='/' state={{ from: location }} replace />
    )
}

export default RequireAuth
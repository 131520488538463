import React, { useEffect, useState } from 'react';
import { useData } from '../../context/DataContext';
import useRefreshToken from '../../hooks/useRefreshToken';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate } from 'react-router-dom';
import Navigation from '../../components/Navigation';
import './Dashboard.css';
import useAuth from '../../hooks/useAuth';

const Dashboard = () => {
    const { data } = useData();
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const refresh = useRefreshToken();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const [ empCount, setEmpCount ] = useState('');
    const [ mngCount, setMngCount ] = useState('');
    const [ roCount, setRoCount ] = useState('');
    const [ coCount, setCopCount ] = useState('');
    
    // count users
    const [ userManagement, setUserManagement ] = useState('');
    const [ userOthers, setUserOthers ] = useState('');
    const [ activeMale, setActiveMale ] = useState('');
    const [ activeFemale, setActiveFemale ] = useState('');
    const [ bankRO, setBankRO ] = useState('');
    const [ bankCO, setBankCO ] = useState('');
    const [ dialogRO, setDialogRO ] = useState('');
    const [ dialogCO, setDialogCO ] = useState('');
    
    // search option use states
    const [ users, setUsers ] = useState([]);
    const [ empName, setEmpName ] = useState('');
    const [ managerName, setManagerName ] = useState('');
    const [ empDept, setEmpDept ] = useState('');

    useEffect(() => {
        getUsers();
    }, []);
    
    const getUsers = async () => {
        const response = await axiosPrivate.get('/users');
        const userData = response.data;
        // setUsers(userData);
        // set count for the cards
        const activeUsers = userData.filter(user => user.emp_status === 'Active');
        setEmpCount(activeUsers.length);
        const managers = activeUsers.filter(user => user.user_role === 'Manager')
        setMngCount(managers.length)
        const ro = activeUsers.filter(user => user.user_role === 'Recovery Officer')
        setRoCount(ro.length)
        const co = activeUsers.filter(user => user.user_role === 'Coordinator')
        setCopCount(co.length)

        // call get active users function
        getActiveUsers(userData);
    };

    const getActiveUsers = (data) => {
        const activeEmployee = data.filter(user => user.emp_status === 'Active');
        // total employee
        // setTotalEmp(data.length);
        const topManagementList = ['MIS', 'Secretary', 'Admin', 'HR', 'Accountant'];
        const topManagement = activeEmployee.filter(user => 
            topManagementList?.includes(user.user_role)
        );
        setUserManagement(topManagement.length);
        const otherEmployees = activeEmployee.filter(user => 
            !topManagementList?.includes(user.user_role)
        );
        setUserOthers(otherEmployees.length);
        // male and female count
        setActiveMale(activeEmployee.filter(user => user.gender === 'Male').length);
        setActiveFemale(activeEmployee.filter(user => user.gender === 'Female').length);
        // bank RO and CO count
        setBankRO(activeEmployee.filter(user => user.emp_job_section === 'Bank' && user.emp_designation === 'Recovery Officer').length);
        setBankCO(activeEmployee.filter(user => user.emp_job_section === 'Bank' && user.emp_designation === 'Coordinator').length);
        // dialog RO & CO count
        setDialogRO(activeEmployee.filter(user => user.emp_job_section === 'Dialog' && user.emp_designation === 'Recovery Officer').length);
        setDialogCO(activeEmployee.filter(user => user.emp_job_section === 'Dialog' && user.emp_designation === 'Coordinator').length);

        // const deptList = [...new Set(data.map(user => user.emp_job_section))];
        setUsers([...new Set(data.map(user => user.emp_job_section))]);
    }

    const handleSearchEmployee = () => {
        const allowedUsers = ['Chairman', 'MIS', 'Secretary', 'Admin', 'HR', 'Accountant', 'Assistant'];

        if (allowedUsers?.includes(auth.userRole)) {
            if (empName) {
                navigate(`/employee?search=${empName}`);
            } else if (empDept) {
                navigate(`/employee?search=${empDept}`);
            } else if (managerName) {
                navigate(`/employee?search=${managerName}`);
            } else {
                console.error('search failed');
            }
        } else {
            alert("You don't have permission to search here.");
        }
    }

    return (
        <div className='container-fluid d-flex vh-100 p-0 m-0 bg-light'>
            {/* Section: Navigation */}
            <div className='col-2 vh-100 position-fixed'>
                <Navigation />
            </div>

            {/* Section: Dashboard */}
            <div className="col-10 position-absolute top-0 end-0 pb-5 bg-light">
                {/* Header */}
                <div id="header" className='d-flex justify-content-between mt-4 px-5 py-3'>
                    <div className='text-start d-flex align-items-start'>
                        <div className='fs-5'>
                            Hello
                            {data && (
                                <span className='bg-green mx-0 px-0 rounded fw-semibold'>
                                    &nbsp;{data.user.name}&nbsp;
                                </span>
                            )}
                            ,
                        </div>
                    </div>
                    <div id="header" className='d-flex'>
                        <div className='d-flex flex-column text-end'>
                            <div className='fs-5 fs-xxl-2 fw-medium'>Sensus BPO Services - HRM</div>
                            <div className='responsive-font-sm fw-light'>Manage Employee data with care!</div>
                        </div>
                    </div>
                </div>
                {/* Employee Status Count */}
                <div id="header" className='d-flex justify-content-between px-5 py-3'>
                    <div className='text-start w-100 d-flex justify-content-between'>
                        <div className='ps-3 pt-3 border-0 rounded-3 w-25 d-flex align-items-end bg-white shadow-sm'>
                            <div className='w-25'>
                                <img src="assets/user-employee.png" alt="emp" className='w-100' />
                            </div>
                            <div className='h-100 ms-3 pb-3 d-flex flex-column justify-content-center'>
                                <div className='responsive-font-sm fw-semibold'>Employees</div>
                                <div className='responsive-font-md fw-bold'>{empCount}</div>
                            </div>
                        </div>
                        <div className='ms-2 me-1 ps-3 pt-3 border-0 rounded-3 w-25 d-flex align-items-end bg-white shadow-sm'>
                            <div className='w-25'>
                                <img src="assets/user-manager.png" alt="emp" className='w-100' />
                            </div>
                            <div className='h-100 ms-3 pb-3 d-flex flex-column justify-content-center'>
                                <div className='responsive-font-sm fw-semibold'>Top Management</div>
                                <div className='responsive-font-md fw-bold'>{userManagement}</div>
                            </div>
                        </div>
                        <div className='ms-1 me-2 ps-3 pt-3 border-0 rounded-3 w-25 d-flex align-items-end bg-white shadow-sm'>
                            <div className='w-25'>
                                <img src="assets/user-ro.png" alt="emp" className='w-100' />
                            </div>
                            <div className='h-100 ms-3 pb-3 d-flex flex-column justify-content-center'>
                                <div className='responsive-font-sm fw-semibold'>Male</div>
                                <div className='responsive-font-md fw-bold'>{activeMale}</div>
                            </div>
                        </div>
                        <div className='ps-3 pt-3 border-0 rounded-3 w-25 d-flex align-items-end bg-white shadow-sm'>
                            <div className='w-25'>
                                <img src="assets/user-female.png" alt="emp" className='w-100' />
                            </div>
                            <div className='h-100 ms-3 pb-3 d-flex flex-column justify-content-center'>
                                <div className='responsive-font-sm fw-semibold'>Female</div>
                                <div className='responsive-font-md fw-bold'>{activeFemale}</div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Functional Compnents */}
                <div id="content-1" className='d-flex mt-3 px-5 w-100'>
                    <div className='w-25 me-2 pt-3 pb-5 px-4 d-flex flex-column border-0 rounded-3 bg-white shadow'>
                        <div className='mb-2 text-start'>
                            <div className='fs-6 fw-semibold'>Employee Count</div>
                            <div className='responsive-font-sm fw-light'>Overview of employees</div>
                        </div>
                        <div className='text-start'>
                            <div className='mb-3 d-flex flex-column responsive-font-sm'>
                                <div className='fw-semibold'>Active Employees</div>
                                <div className='d-flex justify-content-between align-items-center border-bottom'>
                                    <div className='ps-2 py-1'><span className='fs-6 text-success'>&bull;</span>&ensp;Top Management</div>
                                    <div className='pe-2 '>{userManagement}</div>
                                </div>
                                <div className='d-flex justify-content-between align-items-center border-bottom'>
                                    <div className='ps-2 py-1'><span className='fs-6 text-primary'>&bull;</span>&ensp;Other Employees</div>
                                    <div className='pe-2 '>{userOthers}</div>
                                </div>
                            </div>
                            <div className='mb-3 d-flex flex-column responsive-font-sm'>
                                <div className='fw-semibold'>Categorized</div>
                                <div className='d-flex justify-content-between align-items-center border-bottom'>
                                    <div className='ps-2 py-1'><span className='fs-6 text-info'>&bull;</span>&ensp;Male</div>
                                    <div className='pe-2 '>{activeMale}</div>
                                </div>
                                <div className='d-flex justify-content-between align-items-center border-bottom'>
                                    <div className='ps-2 py-1'><span className='fs-6 text-warning'>&bull;</span>&ensp;Female</div>
                                    <div className='pe-2 '>{activeFemale}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-75 ms-2 pt-3 pb-4 px-4 border-0 rounded-3 shadow bg-white'>
                        <div className='text-start mb-3'>
                            <div className='fw-semibold fs-6'>Search</div>
                            <div className='responsive-font-sm fw-light'>Search employees with name or section or manager.</div>
                        </div>
                        <div className='d-flex w-100 justify-content-between'>
                            <div className='w-50 d-flex justify-content-center align-items-center'>
                                <div><img src="assets/img-search.jpg" alt="report image" className='w-100 mx-auto'/></div>
                            </div>
                            <div className='w-50 d-flex flex-column justify-content-center'>
                                <div className='d-flex flex-column responsive-font-sm'>
                                    <div className='mb-3 text-start'>
                                        <label htmlFor="empName">Employee Name</label>
                                        <input type="text" name="emp-name" id="empName" 
                                            className='w-100 rounded border border-light-subtle mt-2 py-2 ps-3 no-border-on-focus'
                                            placeholder='John Doe'
                                            value={empName}
                                            onChange={(e) => setEmpName(e.target.value)}/>
                                    </div>
                                    <div className='mb-3 text-start'>
                                        <label htmlFor="empDept">Department</label>
                                        <select name="department" id="empDept" className='form-select mt-2 py-2 ps-3 rounded border border-light-subtle' style={{fontSize: '0.9rem'}}
                                            value={empDept}
                                            onChange={(e) => setEmpDept(e.target.value)}>
                                            <option value="">Select Department</option>
                                            { users.map ((user, index) => (
                                                <option key={index} value={user}>{user}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='mt-2'>
                                    <button className='btn-next border-0 py-2 w-100 bg-green text-dark rounded responsive-font-sm fw-semibold shadow-sm' onClick={() => handleSearchEmployee()}>Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;
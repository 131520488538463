import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSpinner,
  faCoffee,
  faUser,
  faSearch,
  faCircleNotch,
  faCircleXmark,
  faCircleCheck,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";

library.add(
  faSpinner,
  faCoffee,
  faUser,
  faSearch,
  faCheckCircle,
  faCircleNotch,
  faCircleXmark,
  faCircleCheck,
  faCircleExclamation,
);

import React, { useEffect, useState } from 'react'

import Navigation from '../../components/Navigation';

import './Recruit.css';
import { useNavigate, Link } from 'react-router-dom';
import authService from '../../services/authService';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';

function ViewReport() {
    const [collectionData, setCollectionData] = useState([]);
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const [searchUser, setSearchUser] = useState('');
    
    useEffect(() => {
        const getCollectionData = async () => {
            // const data = await authService.getAllUsers();
            // setUsers(data);
            const response = await axiosPrivate.get('/collection');
            const userData = response.data;
            setCollectionData(userData);
            // const user = localStorage.getItem('user');
            // const localUserData = JSON.parse(localStorage.getItem('user'));
            // console.log('Local User Data: ', localUserData);
            // const validUsers = userData.filter(user => user.user_id === localUserData.user.user_id);
            // console.log('Allowed Users:', validUsers);
            // setEvaluate(validUsers);
        };

        getCollectionData();
    }, []);

    // const handleGetEvaluateDetails = (emp_no) => {
    //     navigate(`/evaluation/view?emp_no=${emp_no}`);
    //     // auth?.userRole === 'HR'
    //     //     ? navigate(`/evaluation/edit?emp_id=${emp_id}`)
    //     //     : navigate(`/employee/view?emp_id=${emp_id}`);
    // }

    // const handleSearchUser = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const response = await axiosPrivate.get(`/evaluation/${searchUser}`);
    //         console.log('User found:', response.data);
    //         const userData = response.data;
    //         setCollectionData(userData);
    //     } catch (error) {
    //         console.error('Error fetching user data:', error);
    //     }
    // }

  return (
    <div className='d-flex poisition-relative'>
        <div className='col-2 vh-100 position-fixed'>
            <Navigation />
        </div>
        {/* Header and Search Bar */}
        <div className='col-10 position-absolute top-0 end-0'>
            <div id="header" className='d-flex justify-content-between px-5 py-3'>
                <div className='text-start pt-3'>
                    <div className='fs-4'><span className='bg-green mx-0 px-0 rounded fw-semibold'>&nbsp;Collection&nbsp;</span>Data,</div>
                    <div className='fs-6 fw-light'>&nbsp;Manage employee data. Add new. Select to edit.</div>
                </div>
                {/* <form onSubmit={handleSearchUser}>
                    <div className='d-flex align-items-center'>
                        <div className='border px-3 rounded'>
                            <img src="/assets/icon-search.png" alt="search icon" className='pe-3'/>
                            <input type="text" name="search" id="search" className='bg-transparent border-0 py-2 no-border-on-focus' placeholder='Search'
                                value={searchUser}
                                onChange={(e) => setSearchUser(e.target.value)} />
                        </div>
                        <div className='ps-3'>
                            <button className='border rounded py-2 px-4 btn-search shadow-sm btn-add'>Search</button>
                        </div>
                    </div>
                </form> */}
            </div>

            {/* Body context */}
            <div className='mt-1 px-5 mb-5'>
                <div className='mt-3 border border-bottom-0 rounded-3 h-auto'>
                    <table className="table table-striped responsive-font-sm">
                        <thead>
                            <tr className='text-center'>
                                <th className='border-end'>S/N</th>
                                <th className='border-end'>Employee No</th>
                                <th className='border-end'>Employee Name</th>
                                <th className='border-end'>Collection Location</th>
                                <th className=''>Collection Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {collectionData.map((data, index) => (
                                <tr 
                                    key={data.emp_id} 
                                    // onClick={() => handleGetEvaluateDetails(data.emp_id)}
                                    role='button'>
                                    <td className='border-end'>{index + 1}</td>
                                    <td className='border-end'>{data.emp_id}</td>
                                    <td className='border-end text-start'>{data.emp_name}</td>
                                    <td className='border-end'>{data.c_location}</td>
                                    <td className=''>{data.c_amount}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <div className='py-3 bg-dark text-center text-light'>
                &copy;SensesHub BPO Services. 2024
            </div> */}
        </div>
    </div>
  )
}

export default ViewReport;
import React, { createContext, useState, useEffect } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    // const [auth, setAuth] = useState();
    const [auth, setAuth] = useState(() => {
      // Retrieve the auth state from localStorage if available
      const storedAuth = localStorage.getItem('auth');
      return storedAuth ? JSON.parse(storedAuth) : null;
    });

    // useEffect to rehydrate auth state from localStorage when the component mounts
    useEffect(() => {
      if (auth) {
          localStorage.setItem('auth', JSON.stringify(auth));
      } else {
          localStorage.removeItem('auth');
      }
    }, [auth]);

  return (
     <AuthContext.Provider value={{ auth, setAuth }}>
        {children}
    </AuthContext.Provider>
  );
}

export default AuthContext
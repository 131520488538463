import React from 'react'

function Footer() {
  return (
    <div className='h-100 d-flex justify-content-center align-items-center bg-dark text-white'>
        <div className='responsive-font-sm'>2024 © SensusHub</div>
    </div>
  )
}

export default Footer
import React, { useState } from "react";
import styles from "./Login.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "../../components/Footer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

function PasswordReset() {
  const navigate = useNavigate();
  // input data
  const [empNo, setEmpNo] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  // system functionalities
  const [loading, setLoading] = useState(false);
  const [validUser, setValidUser] = useState(false);
  const [status, setStatus] = useState("");

  const checkEmpNo = async () => {
    if (empNo === "") {
      console.log("Please enter valid employee no.");
      setStatus("Please enter valid employee no.");
      return null;
    }
    try {
      const response = await axios.get(`/auth/forget-password/${empNo}`);
      setEmpNo(response.data.data);
      setValidUser(true);
      setStatus("");
    } catch (error) {
      console.error(error.response.data.message);
      setStatus(error.response.data.message);
    }
  };

  const handleChangePassword = async (e) => {
    setLoading(true);
    e.preventDefault();
    const passwords = {
      emp_no: empNo,
      current_password: currentPassword,
      new_password: newPassword,
    };
    try {
      const response = await axios.post("/auth/forget-password", passwords);
      console.log(response);
      // reset states
      setStatus("");
      setEmpNo("");
      setCurrentPassword("");
      setNewPassword("");
      alert(response.data.success);
      navigate("/login");
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      if (error.response && error.response.data.message) {
        console.error(error.response.data.message);
        setStatus(error.response.data.message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        console.error(error.message);
      }
    }
  };

  const handleResetForm = () => {
    setValidUser(false);
    setStatus("");
    setEmpNo("");
    setCurrentPassword("");
    setNewPassword("");
  };

  return (
    <div>
      <div className="vh-100">
        <div
          className={`d-flex flex-column justify-content-center align-items-center ${styles.h20}`}
        >
          <h1 className="fw-bold">Password Reset</h1>
          <h5 className="fw-light">Change your password here.</h5>
        </div>
        <div className="h-75 d-flex justify-content-center align-items-start">
          <div
            className={`w-75 ${styles.h90} d-flex border rounded-3 ${styles.bgLinearGreenReverse} shadow`}
          >
            <div className="w-50 h-100 d-flex justify-content-center align-items-center">
              <div className="w-75 h-75 d-flex flex-column align-items-center">
                <div className="w-100">
                  <div>
                    <label htmlFor="empNo" className="ps-1">
                      Employee Number
                    </label>
                    <input
                      type="text"
                      name="emp-no"
                      id="empNo"
                      className="w-100 mt-2 py-2 ps-3 border border-light-subtle rounded no-border-on-focus"
                      placeholder="E.g.: EMP125639"
                      value={empNo}
                      onChange={(e) => setEmpNo(e.target.value)}
                    />
                  </div>
                  {validUser ? (
                    <></>
                  ) : (
                    <div className="mt-3">
                      <button
                        type="submit"
                        className="w-25 btn btn-dark"
                        onClick={checkEmpNo}
                      >
                        Check
                      </button>
                    </div>
                  )}
                </div>
                {validUser ? (
                  <form
                    action="#"
                    className="w-100"
                    onSubmit={handleChangePassword}
                    onReset={handleResetForm}
                  >
                    <div className="mt-4">
                      <label htmlFor="currentPassword" className="ps-1">
                        Current Password
                      </label>
                      <input
                        type="password"
                        name="current-password"
                        id="currentPassword"
                        className="w-100 mt-2 py-2 ps-3 border border-light-subtle rounded no-border-on-focus"
                        placeholder="Current Password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                      />
                    </div>
                    <div className="mt-4">
                      <label htmlFor="newPassword" className="ps-1">
                        New Password
                      </label>
                      <input
                        type="password"
                        name="new-password"
                        id="newPassword"
                        className="w-100 mt-2 py-2 ps-3 border border-light-subtle rounded no-border-on-focus"
                        placeholder="New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </div>
                    <div className="mt-5 d-flex">
                      <button
                        type="submit"
                        className="w-25 btn btn-dark"
                        disabled={loading}
                      >
                        Submit
                        {loading && (
                          <FontAwesomeIcon
                            icon="spinner"
                            style={{ color: "#FFF" }}
                            className="ms-3"
                            spin
                          />
                        )}
                      </button>
                      <button
                        type="reset"
                        className="w-25 ms-3 btn btn-light border"
                      >
                        Clear
                      </button>
                    </div>
                  </form>
                ) : status === "" ? (
                  <></>
                ) : (
                  <div
                    className={`w-100 mt-3 p-2 border border-danger bg-danger-subtle bg-opacity-50 rounded-2 ${styles.fsWarning}`}
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-exclamation"
                      className="mx-3"
                    />
                    {status}
                  </div>
                )}
                {validUser === true && status !== "" ? (
                  <div
                    className={`w-100 mt-4 p-2 border border-danger bg-danger-subtle bg-opacity-50 rounded-2 ${styles.fsWarning}`}
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-exclamation"
                      className="mx-3"
                    />
                    {status}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="w-50 d-flex justify-content-center align-items-center">
              <img
                src="/assets/img-password-reset.png"
                alt="password reset"
                className="w-75"
              />
            </div>
          </div>
        </div>
        <div className={styles.h5}>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default PasswordReset;

import React, { useContext, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { AppContext } from '../context/AppContext';
import { useData } from '../context/DataContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function Navigation() {
    const navigate = useNavigate();
    // access user variable using context API
    // const { user } = useContext(AppContext);
    const { data, setData } = useData();

    const navigationList = [
        {name: 'Dashboard', href: '/dashboard', src: '/assets/icon-dashboard.png', src_active: '/assets/icon-dashboard-white.png'},
        {name: 'Employee Portal', href: '/employee', src: '/assets/icon-registration.png', src_active: '/assets/icon-registration-white.png'},
        {name: 'Performance Evaluate', href: '/evaluation', src: '/assets/icon-profile.png', src_active: '/assets/icon-profile-white.png'},
        {name: 'Generate Reports', href: '/report', src: '/assets/icon-doc.png', src_active: '/assets/icon-doc-white.png'},
    ];

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            setData(JSON.parse(userData));
        }
    }, []);

    const handleLogout = () => {
        // Clear data from local storage
        localStorage.removeItem('user');
        localStorage.removeItem('auth');
    
        // Perform additional cleanup if necessary
        setData(null);
    
        // Redirect to login or home page
        navigate('/login');
    };

    return (
        <div className="bg-dark d-flex flex-column justify-content-between vh-100">
            <div>
                <div className='py-5 d-flex justify-content-center'>
                    <img src="/assets/logo-white.png" alt="website-logo-white" />
                </div>
                <div>
                    {navigationList.map((item) => (
                        <NavLink 
                            to={item.href} 
                            key={item.name}
                            className='text-decoration-none text-reset'>
                            {({ isActive }) => (
                                <div className={`d-flex align-items-center responsive-font-sm py-3 mb-1 nav-item ${isActive? 'bg-light fw-medium': 'color-nav-item text-light'}`}>
                                    <div className='ms-3 d-flex align-items-center'>
                                        <img src={isActive? item.src : item.src_active } alt="navigation icon" />
                                    </div>
                                    <div className='ms-xl-2 ms-lg-1'>{item.name}</div>
                                </div>
                            )}
                        </NavLink>
                    ))}
                </div>
            </div>
            <div>
                <div className='mx-3 py-4 d-flex justify-content-center align-items-center border-top' role="button">
                    <div className='px-2'><img src="/assets/icon-user.png" alt="icon-user" width="40px" /></div>
                    <div className='ml-3 text-light d-flex flex-column text-start'>
                        {data && (
                            <div className='responsive-font-sm'>{data.user.name}</div>
                        )}
                        {data && (
                            <div className='responsive-font-sm fw-lighter'>{data.user.emp_designation}</div>
                        )}
                    </div>
                    <div className='ms-3 d-flex align-items-center justify-content-center'>
                        <button onClick={handleLogout} className='bg-transparent border-0 border-light rounded-5'>
                            <img src="/assets/icon-logout.png" alt="icon-logout" className='py-2 px-1' />
                        </button>
                    </div>
                </div>
                <div className='text-secondary pb-2 responsive-font-sm'>
                    &copy;2024 <a href="https://sensushub.com/" className='text-decoration-none text-reset'>SensusHub</a>
                </div>
            </div>
        </div>
    )
}

export default Navigation
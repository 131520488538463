import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../../components/Navigation';
import './Evaluation.css';
import authService from '../../services/authService';
import { useNavigate, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const EvaluationForm3 = () => {
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();

    const [ empNo, setEmpNo ] = useState('');
    const [ empName, setEmpName ] = useState('');
    const [ empRole, setEmpRole ] = useState('');
    const [ empJoinedDate, setEmpJoinedDate ] = useState('');
    const [ jobSection, setJobSection ] = useState('');
    const [ designation, setDesignation ] = useState('');
    const [ workType, setWorkType ] = useState('');

    // form data hooks
    // const [ eScore, setEscore ] = useState('');
    const [ evTitle1 ] = useState('Goal Alignment');
    const [ evDesc1 ] = useState('How well does the employee\'s work \ assigned projects contribute to the overall company goals and targets?');
    const [ evScore1, setEvScore1 ] = useState('');
    const [ evTitle2 ] = useState('Process Adherence');
    const [ evDesc2] = useState('How diligently does the employee follow the company\'s established procedures and guidelines to achieve projects targets?');
    const [ evScore2, setEvScore2 ] = useState('');
    const [ evTitle3 ] = useState('Professional Development');
    const [ evDesc3] = useState('How committed is the employee to seeking growth and skill development within their role?');
    const [ evScore3, setEvScore3 ] = useState('');
    const [ evTitle4 ] = useState('Innovation');
    const [ evDesc4] = useState('How often does the employee suggest improvements or new innovative ideas for processes – improvement within the company?');
    const [ evScore4, setEvScore4 ] = useState('');
    const [ evTitle5 ] = useState('Company Representation');
    const [ evDesc5] = useState('How well does the employee represent the company\'s values and brand in their daily work as well as meetings with Clients?');
    const [ evScore5, setEvScore5 ] = useState('');
    const [ evTitle6 ] = useState('Subordinate development');
    const [ evDesc6] = useState('How satisfied are you with the subordinate development efforts?');
    const [ evScore6, setEvScore6 ] = useState('');
    const [ evTitle7 ] = useState('Retention of staff');
    const [ evDesc7] = useState('How satisfied are you with, retention of team members?');
    const [ evScore7, setEvScore7 ] = useState('');
    const [ evTitle8 ] = useState('Maintain of professional relationship with our clients');
    const [ evDesc8 ] = useState('How well he / she maintains   professional relationship with our clients?');
    const [ evScore8, setEvScore8 ] = useState('');

    // evaluation form data
    const formData = [
        {
            title: 'Goal Alignment', 
            desc: 'How well does the employee\'s work \ assigned projects contribute to the overall company goals and targets?', 
            rates: [{
                name: 'goal_alignment',
                score: [1, 2, 3, 4]
            }]
        },
        {
            title: 'Process Adherence', 
            desc: 'How diligently does the employee follow the company\'s established procedures and guidelines to achieve projects targets?', 
            rates: [{
                name: 'process_adherence',
                score: [1, 2, 3, 4]
            }]
        },
        {
            title: 'Professional Development', 
            desc: 'How committed is the employee to seeking growth and skill development within their role?', 
            rates: [{
                name: 'professional_development',
                score: [1, 2, 3, 4]
            }]
        },
        {
            title: 'Innovation', 
            desc: 'How often does the employee suggest improvements or new innovative ideas for processes – improvement within the company?', 
            rates: [{
                name: 'innovation',
                score: [1, 2, 3, 4]
            }]
        },
        {
            title: 'Company Representation', 
            desc: 'How well does the employee represent the company\'s values and brand in their daily work as well as meetings with Clients?', 
            rates: [{
                name: 'company_representation',
                score: [1, 2, 3, 4]
            }]
        },
        {
            title: 'Subordinate development', 
            desc: 'How satisfied are you with the subordinate development efforts?', 
            rates: [{
                name: 'subordinate_development',
                score: [1, 2, 3, 4]
            }]
        },
        {
            title: 'Retention of staff', 
            desc: 'How satisfied are you with, retention of team members?', 
            rates: [{
                name: 'retention_of_staff',
                score: [1, 2, 3, 4]
            }]
        },
        {
            title: 'Maintain of professional relationship with our clients', 
            desc: 'How well he / she maintains   professional relationship with our clients?', 
            rates: [{
                name: 'relationship_with_client',
                score: [1, 2, 3, 4]
            }]
        }
    ];

    useEffect(() => {
        const userData = async () => {
            const searchParam = new URLSearchParams(location.search);
            const emp_id = searchParam.get('emp_id');
            // console.log('emp_id:', emp_id);

            try {
                const response = await axiosPrivate.get(`/users/${emp_id}`);
                // console.log('User found:', response.data);
                const userData = response.data;
                // console.log('User Data:', userData);
                setEmpNo(userData[0].user_id);
                setEmpName(userData[0].name);
                setEmpRole(userData[0].user_role);
                setEmpJoinedDate(userData[0].emp_joined_date.slice(0,10));
                setJobSection(userData[0].emp_job_section);
                setDesignation(userData[0].emp_designation);
                setWorkType(userData[0].emp_work_type);
            } catch (error) {
                console.error('Error fetching user data:', error);
                setEmpName('');
                setEmpJoinedDate('');
                setJobSection('');
                setDesignation('');
                setWorkType('');
            }
        }

        userData();
    }, [location.search])

    const fetchUserData = async (e) => {
        try {
            // const response = await authService.getUser(empNo);
            const response = await axiosPrivate.get(`/users/${empNo}`);
            const userData = response.data;
            // console.log('Response body:', userData[0].name);
            if (userData[0].user_role === 'Manager') {
                setEmpNo(userData[0].user_id);
                setEmpName(userData[0].name);
                setEmpRole(userData[0].user_role);
                setEmpJoinedDate(userData[0].emp_joined_date.slice(0,10));
                setJobSection(userData[0].emp_job_section);
                setDesignation(userData[0].emp_designation);
                setWorkType(userData[0].emp_work_type);
            } else {
                alert('Please enter Recovery Officer employee number to proceed')
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            setEmpName('');
            setEmpJoinedDate('');
            setJobSection('');
            setDesignation('');
            setWorkType('');
        }
    };

    const fetchEvaluationData = async () => {
        const user = JSON.parse(localStorage.getItem('user'));
        // console.log('User : ', user);
        try {
            const response = await axiosPrivate.get(`/evaluation/${empNo}`);
            const evaluateData = response.data;
            // console.log('Evaluate data : ', evaluateData);

            const validEvaluationData = evaluateData.some(data => data.user_id === user.user.user_id);
            // console.log('Valid evaluate data : ', validEvaluationData);

            return validEvaluationData;
        } catch (error) {
            console.log('Error:', error);
            return false;
        }
    }

    // reload data on out of focus
    // const handleBlur = () => {
    //     if (empNo) {
    //         fetchUserData(empNo);
    //     }
    // };

    // reload data from enter
    const handleKeyDown = async (e) => {
        if (e.key === 'Enter') {
            const isEvaluated = await fetchEvaluationData(empNo);
            if (isEvaluated) {
                alert('User already evaluated by you!');
            } else {
                fetchUserData(empNo);
            }
        }
    };

    const handleEvaluation = async (e) => {
        e.preventDefault();
        const user = JSON.parse(localStorage.getItem('user'));
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        const scoreAvg = ((
            parseInt(evScore1) + 
            parseInt(evScore2) + 
            parseInt(evScore3) + 
            parseInt(evScore4) + 
            parseInt(evScore5) + 
            parseInt(evScore6) + 
            parseInt(evScore7)) / 7);
        // console.log('Average: ', (Math.floor(scoreAvg * 100) / 100).toString());
        const newEvaluation = {
            user_id : user.user.user_id,
            user_role: user.user.user_role,
            user_name: user.user.name,
            emp_no: empNo,
            emp_name: empName,
            emp_role: empRole,
            ev_timestamp : formattedDate,
            ev_title_1: evTitle1,
            ev_desc_1: evDesc1,
            ev_score_1: evScore1,
            ev_title_2: evTitle2,
            ev_desc_2: evDesc2,
            ev_score_2: evScore2,
            ev_title_3: evTitle3,
            ev_desc_3: evDesc3,
            ev_score_3: evScore3,
            ev_title_4: evTitle4,
            ev_desc_4: evDesc4,
            ev_score_4: evScore4,
            ev_title_5: evTitle5,
            ev_desc_5: evDesc5,
            ev_score_5: evScore5,
            ev_title_6: evTitle6,
            ev_desc_6: evDesc6,
            ev_score_6: evScore6,
            ev_title_7: evTitle7,
            ev_desc_7: evDesc7,
            ev_score_7: evScore7,
            ev_title_8: evTitle8,
            ev_desc_8: evDesc8,
            ev_score_8: evScore8,
            ev_score_average: (Math.floor(scoreAvg * 100) / 100).toString()
        };
        try {
            const response = await axiosPrivate.post('/evaluation/form', newEvaluation);
            console.log('Evaluation added successfully:', response.data.emp_no);
            alert('Evaluation Successfull');
            setEmpNo('');
            setEmpName('');
            setEmpJoinedDate('');
            setJobSection('');
            setDesignation('');
            setWorkType('');
            setEvScore1('');
            setEvScore2('');
            setEvScore3('');
            setEvScore4('');
            setEvScore5('');
            setEvScore6('');
            setEvScore7('');
            setEvScore8('');
            navigate('/evaluation');
        } catch (error) {
            console.error('Process failed: ', error.response?.data || error.message);
            setEmpNo('');
            setEmpName('');
            setEmpJoinedDate('');
            setJobSection('');
            setDesignation('');
            setWorkType('');
            setEvScore1('');
            setEvScore2('');
            setEvScore3('');
            setEvScore4('');
            setEvScore5('');
            setEvScore6('');
            setEvScore7('');
            setEvScore8('');
        }
    }

  return (
    <div className='d-flex vh-100'>
        {/* Section: Navigation */}
        <div className='col-2 vh-100 position-fixed'>
            <Navigation />
        </div>

        {/* Section: Dashboard */}
        <div className="col-10 position-absolute top-0 end-0 bg-light">
            {/* header */}
            <div id="header" className='d-flex justify-content-between px-5 py-3'>
                <div className='text-start pt-3'>
                    <div className='fs-4'>
                        <span className='bg-green mx-0 px-0 rounded fw-semibold'>&nbsp;Evaluation&nbsp;</span> Scenario,
                    </div>
                    <div className='fs-6 mt-1 fw-light'>Start evaluation Managers here.</div>
                </div>
            </div>

            {/* users */}
            <div className='px-5 py-3'>
                <div className='mb-2 text-start'>
                    <div className='fs-6 fw-semibold'>Employee Details</div>
                    <div className='responsive-font-sm fw-light'>Enter Employee Number to check all the employee details.</div>
                </div>
                <div className='px-4 py-4 mb-4 border rounded position-relative responsive-font-sm bg-white'>
                    <div className="row gx-5">
                        <div className="col">
                            <div className='text-start'>
                                <label htmlFor="empNumber" className='ps-1'>Employee Number</label>
                                <input type="text" name="emp-number" id="empNumber"
                                    className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                    placeholder='E.g.: EMP125639'
                                    value={empNo}
                                    onChange={(e) => setEmpNo(e.target.value)}
                                    onKeyDown={handleKeyDown}/>
                            </div>
                            <div className='text-start mt-3'>
                                <label htmlFor="empName" className='ps-1'>Employee Name</label>
                                <input type="text" name="emp-name" id="empName"
                                    className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                    value={empName} disabled/>
                            </div>
                            <div className='text-start mt-3'>
                                <label htmlFor="empJoinedDate" className='ps-1'>Joined Date</label>
                                <input type="text" name="emp-joined-date" id="empJoinedDate"
                                    className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus' 
                                    value={empJoinedDate} disabled/>
                            </div>
                        </div>
                        <div className="col">
                            <div className='text-start'>
                                <label htmlFor="empJobSection" className='ps-1'>Job Section</label>
                                <input type="text" name="emp-job-section" id="empJobSection"
                                    className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                    value={jobSection} disabled/>
                            </div>
                            <div className='text-start mt-3'>
                                <label htmlFor="empDesignation" className='ps-1'>Designation</label>
                                <input type="text" name="emp-designation" id="empDesignation"
                                    className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                    value={designation} disabled/>
                            </div>
                            <div className='text-start mt-3'>
                                <label htmlFor="empWorkType" className='ps-1'>Work Type</label>
                                <input type="text" name="emp-work-type" id="empWorkType"
                                    className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                    value={workType} disabled/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <hr /> */}
                <div className=''>
                    <div className='text-start'>
                        <div className='fs-6 fw-semibold'>Evaluation Process</div>
                        <div className='responsive-font-sm fw-light'>Make sure to all the required fields in evaluation.</div>
                    </div>
                    <form onSubmit={handleEvaluation}>
                        <div className='border rounded px-4 py-4 mt-2 responsive-font-sm bg-white'>
                            <div className='rating-scale border-0 rounded py-2 px-3 mb-4 d-flex justify-content-between bg-warning-subtle'>
                                <div>
                                    <b>Note:</b> Evaluation rating crietiria (1) Below Average, (2) Average, (3) Good, (4) Very Good. Select most suitable option.
                                </div>
                            </div>
                            <div className='mb-3 text-start p-3 rounded-3 bg-body-tertiary'>
                                <div className='fw-medium'>{evTitle1}</div>
                                <div className='d-flex justify-content-between'>
                                    <div className='ms-3 mt-2'>{evDesc1}</div>
                                    <div className='d-flex gap-5'>
                                        <div className='d-flex gap-5'>
                                            <div>
                                                <input type="radio" name="customer_feedback" id="customerFeedback" value="1"
                                                    checked={evScore1 === '1'}
                                                    onChange={(e) => setEvScore1(e.target.value)} required/>
                                                <label htmlFor="customer_feedback" className='ms-3'>1</label>
                                            </div>
                                            <div>
                                                <input type="radio" name="customer_feedback" id="customerFeedback" value="2"
                                                    checked={evScore1 === '2'}
                                                    onChange={(e) => setEvScore1(e.target.value)}/>
                                                <label htmlFor="customer_feedback" className='ms-3'>2</label>
                                            </div><div>
                                                <input type="radio" name="customer_feedback" id="customerFeedback" value="3"
                                                    checked={evScore1 === '3'}
                                                    onChange={(e) => setEvScore1(e.target.value)}/>
                                                <label htmlFor="customer_feedback" className='ms-3'>3</label>
                                            </div><div>
                                                <input type="radio" name="customer_feedback" id="customerFeedback" value="4"
                                                    checked={evScore1 === '4'}
                                                    onChange={(e) => setEvScore1(e.target.value)}/>
                                                <label htmlFor="customer_feedback" className='ms-3'>4</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='mb-3 text-start p-3 rounded-3 bg-body-tertiary'>
                                <div className='fw-medium'>{evTitle2}</div>
                                <div className='d-flex justify-content-between'>
                                    <div className='ms-3 mt-2'>{evDesc2}</div>
                                    <div className='d-flex gap-5'>
                                        <div className='d-flex gap-5'>
                                            <div>
                                                <input type="radio" name="collaboration" id="customerFeedback" value="1"
                                                    checked={evScore2 === '1'}
                                                    onChange={(e) => setEvScore2(e.target.value)} required/>
                                                <label htmlFor="collaboration" className='ms-3'>1</label>
                                            </div>
                                            <div>
                                                <input type="radio" name="collaboration" id="customerFeedback" value="2"
                                                    checked={evScore2 === '2'}
                                                    onChange={(e) => setEvScore2(e.target.value)}/>
                                                <label htmlFor="collaboration" className='ms-3'>2</label>
                                            </div><div>
                                                <input type="radio" name="collaboration" id="customerFeedback" value="3"
                                                    checked={evScore2 === '3'}
                                                    onChange={(e) => setEvScore2(e.target.value)}/>
                                                <label htmlFor="collaboration" className='ms-3'>3</label>
                                            </div><div>
                                                <input type="radio" name="collaboration" id="customerFeedback" value="4"
                                                    checked={evScore2 === '4'}
                                                    onChange={(e) => setEvScore2(e.target.value)}/>
                                                <label htmlFor="collaboration" className='ms-3'>4</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='mb-3 text-start p-3 rounded-3 bg-body-tertiary'>
                                <div className='fw-medium'>{evTitle3}</div>
                                <div className='d-flex justify-content-between'>
                                    <div className='ms-3 mt-2'>{evDesc3}</div>
                                    <div className='d-flex gap-5'>
                                        <div className='d-flex gap-5'>
                                            <div>
                                                <input type="radio" name="resolution_success" id="customerFeedback" value="1"
                                                    checked={evScore3 === '1'}
                                                    onChange={(e) => setEvScore3(e.target.value)} required/>
                                                <label htmlFor="resolution_success" className='ms-3'>1</label>
                                            </div>
                                            <div>
                                                <input type="radio" name="resolution_success" id="customerFeedback" value="2"
                                                    checked={evScore3 === '2'}
                                                    onChange={(e) => setEvScore3(e.target.value)}/>
                                                <label htmlFor="resolution_success" className='ms-3'>2</label>
                                            </div><div>
                                                <input type="radio" name="resolution_success" id="customerFeedback" value="3"
                                                    checked={evScore3 === '3'}
                                                    onChange={(e) => setEvScore3(e.target.value)}/>
                                                <label htmlFor="resolution_success" className='ms-3'>3</label>
                                            </div><div>
                                                <input type="radio" name="resolution_success" id="customerFeedback" value="4"
                                                    checked={evScore3 === '4'}
                                                    onChange={(e) => setEvScore3(e.target.value)}/>
                                                <label htmlFor="resolution_success" className='ms-3'>4</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='mb-3 text-start p-3 rounded-3 bg-body-tertiary'>
                                <div className='fw-medium'>{evTitle4}</div>
                                <div className='d-flex justify-content-between'>
                                    <div className='ms-3 mt-2'>{evDesc4}</div>
                                    <div className='d-flex gap-5'>
                                        <div className='d-flex gap-5'>
                                            <div>
                                                <input type="radio" name="professionalism" id="customerFeedback" value="1"
                                                    checked={evScore4 === '1'}
                                                    onChange={(e) => setEvScore4(e.target.value)} required/>
                                                <label htmlFor="professionalism" className='ms-3'>1</label>
                                            </div>
                                            <div>
                                                <input type="radio" name="professionalism" id="customerFeedback" value="2"
                                                    checked={evScore4 === '2'}
                                                    onChange={(e) => setEvScore4(e.target.value)}/>
                                                <label htmlFor="professionalism" className='ms-3'>2</label>
                                            </div><div>
                                                <input type="radio" name="professionalism" id="customerFeedback" value="3"
                                                    checked={evScore4 === '3'}
                                                    onChange={(e) => setEvScore4(e.target.value)}/>
                                                <label htmlFor="professionalism" className='ms-3'>3</label>
                                            </div><div>
                                                <input type="radio" name="professionalism" id="customerFeedback" value="4"
                                                    checked={evScore4 === '4'}
                                                    onChange={(e) => setEvScore4(e.target.value)}/>
                                                <label htmlFor="professionalism" className='ms-3'>4</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='mb-3 text-start p-3 rounded-3 bg-body-tertiary'>
                                <div className='fw-medium'>{evTitle5}</div>
                                <div className='d-flex justify-content-between'>
                                    <div className='ms-3 mt-2'>{evDesc5}</div>
                                    <div className='d-flex gap-5'>
                                        <div className='d-flex gap-5'>
                                            <div>
                                                <input type="radio" name="documentation" id="customerFeedback" value="1"
                                                    checked={evScore5 === '1'}
                                                    onChange={(e) => setEvScore5(e.target.value)} required/>
                                                <label htmlFor="documentation" className='ms-3'>1</label>
                                            </div>
                                            <div>
                                                <input type="radio" name="documentation" id="customerFeedback" value="2"
                                                    checked={evScore5 === '2'}
                                                    onChange={(e) => setEvScore5(e.target.value)}/>
                                                <label htmlFor="documentation" className='ms-3'>2</label>
                                            </div><div>
                                                <input type="radio" name="documentation" id="customerFeedback" value="3"
                                                    checked={evScore5 === '3'}
                                                    onChange={(e) => setEvScore5(e.target.value)}/>
                                                <label htmlFor="documentation" className='ms-3'>3</label>
                                            </div><div>
                                                <input type="radio" name="documentation" id="customerFeedback" value="4"
                                                    checked={evScore5 === '4'}
                                                    onChange={(e) => setEvScore5(e.target.value)}/>
                                                <label htmlFor="documentation" className='ms-3'>4</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='mb-3 text-start p-3 rounded-3 bg-body-tertiary'>
                                <div className='fw-medium'>{evTitle6}</div>
                                <div className='d-flex justify-content-between'>
                                    <div className='ms-3 mt-2'>{evDesc6}</div>
                                    <div className='d-flex gap-5'>
                                        <div className='d-flex gap-5'>
                                            <div>
                                                <input type="radio" name="collection_visits" id="customerFeedback" value="1"
                                                    checked={evScore6 === '1'}
                                                    onChange={(e) => setEvScore6(e.target.value)} required/>
                                                <label htmlFor="collection_visits" className='ms-3'>1</label>
                                            </div>
                                            <div>
                                                <input type="radio" name="collection_visits" id="customerFeedback" value="2"
                                                    checked={evScore6 === '2'}
                                                    onChange={(e) => setEvScore6(e.target.value)}/>
                                                <label htmlFor="collection_visits" className='ms-3'>2</label>
                                            </div><div>
                                                <input type="radio" name="collection_visits" id="customerFeedback" value="3"
                                                    checked={evScore6 === '3'}
                                                    onChange={(e) => setEvScore6(e.target.value)}/>
                                                <label htmlFor="collection_visits" className='ms-3'>3</label>
                                            </div><div>
                                                <input type="radio" name="collection_visits" id="customerFeedback" value="4"
                                                    checked={evScore6 === '4'}
                                                    onChange={(e) => setEvScore6(e.target.value)}/>
                                                <label htmlFor="collection_visits" className='ms-3'>4</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='mb-3 text-start p-3 rounded-3 bg-body-tertiary'>
                                <div className='fw-medium'>{evTitle7}</div>
                                <div className='d-flex justify-content-between'>
                                    <div className='ms-3 mt-2'>{evDesc7}</div>
                                    <div className='d-flex gap-5'>
                                        <div className='d-flex gap-5'>
                                            <div>
                                                <input type="radio" name="target_achievement" id="customerFeedback" value="1"
                                                    checked={evScore7 === '1'}
                                                    onChange={(e) => setEvScore7(e.target.value)} required/>
                                                <label htmlFor="target_achievement" className='ms-3'>1</label>
                                            </div>
                                            <div>
                                                <input type="radio" name="target_achievement" id="customerFeedback" value="2"
                                                    checked={evScore7 === '2'}
                                                    onChange={(e) => setEvScore7(e.target.value)}/>
                                                <label htmlFor="target_achievement" className='ms-3'>2</label>
                                            </div><div>
                                                <input type="radio" name="target_achievement" id="customerFeedback" value="3"
                                                    checked={evScore7 === '3'}
                                                    onChange={(e) => setEvScore7(e.target.value)}/>
                                                <label htmlFor="target_achievement" className='ms-3'>3</label>
                                            </div><div>
                                                <input type="radio" name="target_achievement" id="customerFeedback" value="4"
                                                    checked={evScore7 === '4'}
                                                    onChange={(e) => setEvScore7(e.target.value)}/>
                                                <label htmlFor="target_achievement" className='ms-3'>4</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='mb-3 text-start p-3 rounded-3 bg-body-tertiary'>
                                <div className='fw-medium'>{evTitle8}</div>
                                <div className='d-flex justify-content-between'>
                                    <div className='ms-3 mt-2'>{evDesc8}</div>
                                    <div className='d-flex gap-5'>
                                        <div className='d-flex gap-5'>
                                            <div>
                                                <input type="radio" name="maintain" id="customerFeedback" value="1"
                                                    checked={evScore8 === '1'}
                                                    onChange={(e) => setEvScore8(e.target.value)} required/>
                                                <label htmlFor="maintain" className='ms-3'>1</label>
                                            </div>
                                            <div>
                                                <input type="radio" name="maintain" id="customerFeedback" value="2"
                                                    checked={evScore8 === '2'}
                                                    onChange={(e) => setEvScore8(e.target.value)}/>
                                                <label htmlFor="maintain" className='ms-3'>2</label>
                                            </div><div>
                                                <input type="radio" name="maintain" id="customerFeedback" value="3"
                                                    checked={evScore8 === '3'}
                                                    onChange={(e) => setEvScore8(e.target.value)}/>
                                                <label htmlFor="maintain" className='ms-3'>3</label>
                                            </div><div>
                                                <input type="radio" name="maintain" id="customerFeedback" value="4"
                                                    checked={evScore8 === '4'}
                                                    onChange={(e) => setEvScore8(e.target.value)}/>
                                                <label htmlFor="maintain" className='ms-3'>4</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-4 d-flex justify-content-center gap-5'>
                                <button className='border rounded py-2 px-4 shadow-sm w-25 btn-active'>Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className='py-3 bg-dark text-center text-light'>
                &copy;SensesHub BPO Services. 2024
            </div>
        </div>
    </div>
  )
}

export default EvaluationForm3
import axios from 'axios';
import useAuth from './useAuth';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

const useRefreshToken = () => {
    const { setAuth } = useAuth();
    const refresh = async () => {
        const response = await axios.get('/refresh');
        setAuth(prev => {
            return {...prev, accessToken: response.data.accessToken}
        })
        return response.data.accessToken;
    }
    return refresh;
}

export default useRefreshToken;
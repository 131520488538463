import { Routes, Route } from 'react-router-dom';
import Layout from './pages/Home/Layout';
import './App.css';
import RequireAuth from './components/RequireAuth';
import { publicRoutes, employeeRoutes, evaluationRoutes, reportRoutes } from './config/routesConfig';

const App = () => {
  return (
    <Routes>
      <Route path="/" Component={Layout}>
        {/* Public Routes */}
        {publicRoutes.map((route, index) => (
          <Route key={index} path={route.path} Component={route.component} />
        ))}

        {/* Private Routes */}
        {[...employeeRoutes, ...evaluationRoutes, ...reportRoutes].map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={<RequireAuth allowedRoles={route.roles} />}
          >
            <Route path={route.path} Component={route.component} />
          </Route>
        ))}
      </Route>
    </Routes>
  );
}

export default App;
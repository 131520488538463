import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../../components/Navigation';
import './Evaluation.css';
import authService from '../../services/authService';
import { useNavigate, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const ViewEvaluationData = () => {
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();

    const [ empNo, setEmpNo ] = useState('');
    const [ empName, setEmpName ] = useState('');
    const [ empRole, setEmpRole ] = useState('');
    const [ empJoinedDate, setEmpJoinedDate ] = useState('');
    const [ jobSection, setJobSection ] = useState('');
    const [ designation, setDesignation ] = useState('');
    const [ workType, setWorkType ] = useState('');

    // form data hooks
    const [ eScore, setEscore ] = useState('');
    // evaluation form data

    useEffect(() => {
        const userData = async () => {
            const searchParam = new URLSearchParams(location.search);
            const emp_no = searchParam.get('emp_no');
            // console.log('emp_id:', emp_id);

            try {
                const response = await axiosPrivate.get(`/evaluation/${emp_no}`);
                // console.log('User found:', response.data);
                const userData = response.data;
                // console.log('User Data:', userData);
                setEmpNo(userData[0].user_id);
                setEmpName(userData[0].name);
                setEmpRole(userData[0].user_role);
                setEmpJoinedDate(userData[0].emp_joined_date.slice(0,10));
                setJobSection(userData[0].emp_job_section);
                setDesignation(userData[0].emp_designation);
                setWorkType(userData[0].emp_work_type);
                const formData = [
                    {
                        title: userData[0].ev_title_1, 
                        desc: 'How often does RO receive positive customer feedback following a visit?', 
                        rates: [{
                            name: 'customer_feedback',
                            score: [1, 2, 3, 4]
                        }]
                    },
                    {
                        title: userData[0].ev_title_2, 
                        desc: 'How well does the recovery officer communicate and coordinate with office staff regarding specific cases?', 
                        rates: [{
                            name: 'collaboration',
                            score: [1, 2, 3, 4]
                        }]
                    },
                    {
                        title: userData[0].ev_title_3, 
                        desc: 'How successful are recovery officers at resolving customer issues and securing payments after initial contact?', 
                        rates: [{
                            name: 'resolution_success',
                            score: [1, 2, 3, 4]
                        }]
                    },
                    {
                        title: userData[0].ev_title_4, 
                        desc: 'How consistently does the recovery officer maintain professionalism and a positive demeanor during customer interactions?', 
                        rates: [{
                            name: 'professionalism',
                            score: [1, 2, 3, 4]
                        }]
                    },
                    {
                        title: userData[0].ev_title_5, 
                        desc: 'How accurately and diligently does the recovery officer update case information and records (DCR)?', 
                        rates: [{
                            name: 'documentation',
                            score: [1, 2, 3, 4]
                        }]
                    },
                    {
                        title: userData[0].ev_title_5, 
                        desc: 'How satisfied are you with the collection visits of the recovery officer?', 
                        rates: [{
                            name: 'collection_visits',
                            score: [1, 2, 3, 4]
                        }]
                    },
                    {
                        title: userData[0].ev_title_7, 
                        desc: 'How consistently does the RO meet or exceed their individual collection targets?', 
                        rates: [{
                            name: 'target_achievement',
                            score: [1, 2, 3, 4]
                        }]
                    }
                ];
            } catch (error) {
                console.error('Error fetching user data:', error);
                setEmpName('');
                setEmpJoinedDate('');
                setJobSection('');
                setDesignation('');
                setWorkType('');
            }
        }

        userData();
    }, [location.search])

    const fetchUserData = async (e) => {
        // try {
        //     // const response = await authService.getUser(empNo);
        //     const response = await axiosPrivate.get(`/users/${empNo}`);
        //     const userData = response.data;
        //     // console.log('Response body:', userData[0]);
        //     if (userData[0].user_role === 'Recovery Officer') {
        //         setEmpNo(userData[0].user_id);
        //         setEmpName(userData[0].name);
        //         setEmpRole(userData[0].user_role);
        //         setEmpJoinedDate(userData[0].emp_joined_date.slice(0,10));
        //         setJobSection(userData[0].emp_job_section);
        //         setDesignation(userData[0].emp_designation);
        //         setWorkType(userData[0].emp_work_type);
        //     } else {
        //         alert('Please enter Recovery Officer employee number to proceed');
        //     }
        // } catch (error) {
        //     console.error('Error fetching user data:', error);
        //     setEmpName('');
        //     setEmpJoinedDate('');
        //     setJobSection('');
        //     setDesignation('');
        //     setWorkType('');
        // }
    };

    // reload data on out of focus
    const handleBlur = () => {
        if (empNo) {
            fetchUserData(empNo);
        }
    };

    // reload data from enter
    // const handleKeyDown = (e) => {
    //     if (e.key === 'Enter')  {
    //         fetchUserData(empNo);
    //     }
    // };

    // const handleEvaluation = async (e) => {
    //     e.preventDefault();
    //     const user = JSON.parse(localStorage.getItem('user'));
    //     const today = new Date();
    //     const year = today.getFullYear();
    //     const month = String(today.getMonth() + 1).padStart(2, '0');
    //     const day = String(today.getDate()).padStart(2, '0');
    //     const formattedDate = `${year}-${month}-${day}`;
    //     const scoreAvg = ((
    //         parseInt(evScore1) + 
    //         parseInt(evScore2) + 
    //         parseInt(evScore3) + 
    //         parseInt(evScore4) + 
    //         parseInt(evScore5) + 
    //         parseInt(evScore6) + 
    //         parseInt(evScore7)) / 7);
    //     // console.log('Average: ', (Math.floor(scoreAvg * 100) / 100).toString());
    //     const newEvaluation = {
    //         // user_id : user.user.user_id,
    //         // user_role: user.user.user_role,
    //         // emp_no: empNo,
    //         // emp_name: empName,
    //         // emp_role: empRole,
    //         // ev_timestamp : formattedDate,
    //         // ev_title_1: evTitle1,
    //         // ev_desc_1: evDesc1,
    //         // ev_score_1: evScore1,
    //         // ev_title_2: evTitle2,
    //         // ev_desc_2: evDesc2,
    //         // ev_score_2: evScore2,
    //         // ev_title_3: evTitle3,
    //         // ev_desc_3: evDesc3,
    //         // ev_score_3: evScore3,
    //         // ev_title_4: evTitle4,
    //         // ev_desc_4: evDesc4,
    //         // ev_score_4: evScore4,
    //         // ev_title_5: evTitle5,
    //         // ev_desc_5: evDesc5,
    //         // ev_score_5: evScore5,
    //         // ev_title_6: evTitle6,
    //         // ev_desc_6: evDesc6,
    //         // ev_score_6: evScore6,
    //         // ev_title_7: evTitle7,
    //         // ev_desc_7: evDesc7,
    //         // ev_score_7: evScore7,
    //         // ev_title_8: evTitle8,
    //         // ev_desc_8: evDesc8,
    //         // ev_score_8: evScore8,
    //         // ev_score_average: (Math.floor(scoreAvg * 100) / 100).toString()
    //     };
    //     try {
    //         const response = await axiosPrivate.post('/evaluation/form', newEvaluation);
    //         console.log('Evaluation added successfully:', response.data);
    //         alert('Evaluation Successfull');
    //         setEmpNo('');
    //         setEmpName('');
    //         setEmpJoinedDate('');
    //         setJobSection('');
    //         setDesignation('');
    //         setWorkType('');
    //         setEvScore1('');
    //         setEvScore2('');
    //         setEvScore3('');
    //         setEvScore4('');
    //         setEvScore5('');
    //         setEvScore6('');
    //         setEvScore7('');
    //     } catch (error) {
    //         console.error('Process failed: ', error.response?.data || error.message);
    //         setEmpNo('');
    //         setEmpName('');
    //         setEmpJoinedDate('');
    //         setJobSection('');
    //         setDesignation('');
    //         setWorkType('');
    //         setEvScore1('');
    //         setEvScore2('');
    //         setEvScore3('');
    //         setEvScore4('');
    //         setEvScore5('');
    //         setEvScore6('');
    //         setEvScore7('');
    //     }
    // }

  return (
    <div className='d-flex vh-100'>
        {/* Section: Navigation */}
        <div className='col-2 vh-100 position-fixed'>
            <Navigation />
        </div>

        {/* Section: Dashboard */}
        <div className="col-10 position-absolute top-0 end-0">
            {/* header */}
            <div id="header" className='d-flex justify-content-between mx-5 px-5 py-3'>
                <div className='text-start pt-3'>
                    <div className='fs-2'>
                        <span className='bg-green mx-0 px-0 rounded fw-semibold'>&nbsp;Evaluation&nbsp;</span> Scenario,
                    </div>
                    <div className='fs-5 mt-1 fw-light'>Start evaluation Recovery Officers here.</div>
                </div>
                <div className='d-flex align-items-center'>
                    <div className='border px-3 rounded'>
                        <img src="assets/icon-search.png" alt="search icon" className='pe-3'/>
                        <input type="text" name="search" id="search" className='bg-transparent border-0 py-2 no-border-on-focus' placeholder='Search' />
                    </div>
                    <div className='ps-3'>
                        <button className='border rounded py-2 px-4 btn-search shadow-sm btn-add'>Search</button>
                    </div>
                </div>
            </div>

            {/* users */}
            <div className='mx-5 px-5 py-3'>
                <div className='mb-3 text-start'>
                    <div className='fs-4'>Employee Details</div>
                    <div className='fs-6 fw-light'>Enter Employee Number to check all the employee details.</div>
                </div>
                    <div className='px-4 py-4 mb-4 border rounded position-relative'>
                        <div className="row gx-5">
                            <div className="col">
                                <div className='text-start'>
                                    <label htmlFor="empNumber" className='ps-1'>Employee Number</label>
                                    <input type="text" name="emp-number" id="empNumber"
                                        className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                        placeholder='E.g.: EMP125639'
                                        value={empNo}
                                        onChange={(e) => setEmpNo(e.target.value)}
                                        onBlur={handleBlur}/>
                                </div>
                                <div className='text-start mt-3'>
                                    <label htmlFor="empName" className='ps-1'>Employee Name</label>
                                    <input type="text" name="emp-name" id="empName"
                                        className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                        value={empName} disabled/>
                                </div>
                                <div className='text-start mt-3'>
                                    <label htmlFor="empJoinedDate" className='ps-1'>Joined Date</label>
                                    <input type="text" name="emp-joined-date" id="empJoinedDate"
                                        className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus' 
                                        value={empJoinedDate} disabled/>
                                </div>
                            </div>
                            <div className="col">
                                <div className='text-start'>
                                    <label htmlFor="empJobSection" className='ps-1'>Job Section</label>
                                    <input type="text" name="emp-job-section" id="empJobSection"
                                        className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                        value={jobSection} disabled/>
                                </div>
                                <div className='text-start mt-3'>
                                    <label htmlFor="empDesignation" className='ps-1'>Designation</label>
                                    <input type="text" name="emp-designation" id="empDesignation"
                                        className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                        value={designation} disabled/>
                                </div>
                                <div className='text-start mt-3'>
                                    <label htmlFor="empWorkType" className='ps-1'>Work Type</label>
                                    <input type="text" name="emp-work-type" id="empWorkType"
                                        className='w-100 rounded border border-light-subtle mt-1 py-2 ps-3 no-border-on-focus'
                                        value={workType} disabled/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <hr /> */}
                    <div className=''>
                        <div className='text-start'>
                            <div className='fs-4'>Evaluation Process</div>
                            <div className='fs-6 fw-light'>Make sure to all the required fields in evaluation.</div>
                        </div>
                        {/* {formData.map((item) => (
                        <div className='mb-3 text-start p-3 rounded-3 bg-body-tertiary' key={item.title}>
                            <div className='fw-medium'>{item.title}</div>
                            <div className='d-flex justify-content-between'>
                                <div className='ms-3 mt-2'>{item.desc}</div>
                                <div className='d-flex gap-5'>
                                    {item.rates.map((rate) => (
                                        <div className='d-flex gap-5' key={rate.name}>
                                            {rate.score.map((value) => (
                                                <div key={value}>
                                                    <input 
                                                        type="radio" 
                                                        name={`${rate.name}-${value}`} 
                                                        id={value}
                                                        value={eScore}
                                                        onChange={(e) => setEscore(e.target.value)}
                                                    />
                                                    <label htmlFor={`${rate.name}-${value}`} className='ms-3'>{value}</label>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))} */}
                    </div>
            </div>
            <div className='py-3 bg-dark text-center text-light'>
                &copy;SensesHub BPO Services. 2024
            </div>
        </div>
    </div>
  )
}

export default ViewEvaluationData
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

const login = async (username, password) => {
    const response = await axios.post('/auth/login', { username, password });
    
    if (response.data.token) {
        localStorage.setItem('user', JSON.stringify(response.data));
    }
    return response.data;
};

const logout = () => {
    localStorage.removeItem('user');
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem('user'));
};

const getUser = async (empNo) => {
    try {
        const response = await axios.get(`/users/${empNo}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user data:', error);
        return [];
    }
}

const evaluationSubmit = async (score, feedback) => {
    try {
        const response = await axios.put(`/evaluation`, {score, feedback});
        return response.data;
    } catch (error) {
        console.error('Error submitting data: ', error);
        return [];
    }
}

const authService = {
    login,
    logout,
    getCurrentUser,
    getUser,
    evaluationSubmit
};

export default authService;
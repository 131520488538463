import React, { useEffect, useState } from 'react'
import Navigation from '../../components/Navigation';
import './Recruit.css';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ViewEvaluationAll() {
    const [evaluate, setEvaluate] = useState([]);
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const [searchUser, setSearchUser] = useState('');
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        setLoading(true);
        getEvaluateData();
    }, []);

    const getEvaluateData = async () => {
        try {
            const response = await axiosPrivate.get('/evaluation');
            const userData = response.data;
            setEvaluate(userData);
        } catch (error) {
            console.error('Error: ', error);
        } finally {
            setLoading(false);
        }
    };

    const handleGetEvaluateDetails = (emp_no) => {
        navigate(`/evaluation/view?emp_no=${emp_no}`);
    }

    const handleSearchUser = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const searchData = evaluate.filter(user => user.emp_no === searchUser);
            setEvaluate(searchData);
        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            setLoading(false);
        }
    }

    const handleSearchInputChange = (e) => {
        const search = e.target.value;
        setSearchUser(search);
        if (search === '' ) {
            getEvaluateData();
        }
    }

  return (
    <div className='d-flex poisition-relative'>
        <div className='col-2 vh-100 position-fixed'>
            <Navigation />
        </div>
        {/* Header and Search Bar */}
        <div className='col-10 vh-100 position-absolute top-0 end-0 bg-light'>
            <div id="header" className='d-flex justify-content-between align-items-end px-5 py-3'>
                <div className='text-start pt-3'>
                    <div className='fs-4'><span className='bg-green mx-0 px-0 rounded fw-semibold'>&nbsp;Evaluation&nbsp;</span>Data,</div>
                    <div className='fs-6 fw-light'>Manage employee data. Add new. Select to edit.</div>
                </div>
                <form onSubmit={handleSearchUser}>
                    <div className='d-flex align-items-center responsive-font-sm'>
                        <div className='border px-3 rounded bg-white'>
                            <img src="assets/icon-search.png" alt="search icon" className='pe-3'/>
                            <input type="text" name="search" id="search" className='bg-transparent border-0 py-2 no-border-on-focus' placeholder='Search'
                                value={searchUser}
                                onChange={handleSearchInputChange} />
                        </div>
                        <div className='ps-3'>
                            <button className='border rounded py-2 px-4 btn-search shadow-sm btn-add'>Search</button>
                        </div>
                    </div>
                </form>
            </div>

            {/* Body context */}
            <div className='mt-1 px-5 mb-5'>
                <div className='mt-3 border border-bottom-0 rounded-3 h-auto'>
                    <table className="table table-striped responsive-font-sm">
                        <thead>
                            <tr className='text-center'>
                                <th className='border-end'>S/N</th>
                                <th className='border-end'>Employee Number</th>
                                <th className='border-end'>Employee Name</th>
                                <th className='border-end'>Employee Role</th>
                                <th className='border-end'>Evaluation Date</th>
                                <th className='border-end'>Average Score</th>
                                <th className='border-end'>Evaluator No</th>
                                <th className=''>Evaluator Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            { loading? (
                                <tr className='border w-100'>
                                    <td colSpan={9}><FontAwesomeIcon icon="spinner" style={{ color: '#000' }} className='ms-3 fs-6' spin /></td>
                                </tr>
                            ) : (
                                Array.isArray(evaluate) && evaluate.length > 0 ? (
                                    evaluate.map((data, index) => (
                                        <tr 
                                            key={index}
                                            // onClick={() => handleGetEvaluateDetails(data.emp_no)}
                                            role='button'>
                                            <td className='border-end'>{index + 1}</td>
                                            <td className='border-end'>{data.emp_no}</td>
                                            <td className='border-end text-start'>{data.emp_name}</td>
                                            <td className='border-end'>{data.emp_role}</td>
                                            <td className='border-end' style={{minWidth: '150px'}}>{data.ev_timestamp?.slice(0, 10) || 'NA'}</td>
                                            <td className='border-end'>{data.ev_score_average}</td>
                                            <td className='border-end'>{data.user_id}</td>
                                            <td className=''>{data.user_name}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="9">No users found.</td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ViewEvaluationAll;
import Login from "../pages/Login/Login";
import Dashboard from "../pages/Dashboard/Dashboard";
import Recruit from "../pages/Employee/Recruit";
import ViewEmployee from "../pages/Employee/ViewEmployee";
import AddEmployee from "../pages/Employee/AddEmployee";
import EditEmployee from "../pages/Employee/EditEmployee";
import Report from "../pages/Report/Report";
import ViewReport from "../pages/Report/ViewReport";
import Evaluation from "../pages/Evalution/Evaluation";
import EvaluationForm1 from "../pages/Evalution/EvaluationForm1";
import EvaluationForm2 from "../pages/Evalution/EvaluationForm2";
import EvaluationForm3 from "../pages/Evalution/EvaluationForm3";
import ViewEvaluation from "../pages/Evalution/ViewEvaluation";
import ViewEvaluationAll from "../pages/Evalution/ViewEvaluationAll";
import ViewEvaluationData from "../pages/Evalution/ViewEvaluationData";
import Unauthorized from "../components/Unauthorized";
import PasswordReset from "../pages/Login/PasswordReset";

export const publicRoutes = [
  { path: "/", component: Login },
  { path: "/login", component: Login },
  { path: "/forget-password", component: PasswordReset },
  { path: "/dashboard", component: Dashboard },
  { path: "/unauthorized", component: Unauthorized },
];

export const employeeRoutes = [
  {
    path: "/employee",
    component: Recruit,
    roles: [
      "Chairman",
      "Secretary",
      "Admin",
      "HR",
      "MIS",
      "AGM",
      "Chief Manager",
      "Senior Manager",
      "Manager",
      "Accountant",
      "Assistant",
    ],
  },
  {
    path: "/employee/view",
    component: ViewEmployee,
    roles: [
      "Chairman",
      "Secretary",
      "Admin",
      "HR",
      "MIS",
      "AGM",
      "Chief Manager",
      "Senior Manager",
      "Manager",
      "Accountant",
      "Assistant",
    ],
  },
  { path: "/employee/add", component: AddEmployee, roles: ["HR", "MIS"] },
  { path: "/employee/edit", component: EditEmployee, roles: ["HR", "MIS"] },
];

export const evaluationRoutes = [
  {
    path: "/evaluation",
    component: Evaluation,
    roles: [
      "Chairman",
      "Secretary",
      "Admin",
      "HR",
      "MIS",
      "AGM",
      "Chief Manager",
      "Senior Manager",
      "Manager",
      "Accountant",
      "Assistant",
    ],
  },
  {
    path: "/evaluation/view",
    component: ViewEvaluation,
    roles: [
      "Chairman",
      "Secretary",
      "Admin",
      "HR",
      "MIS",
      "AGM",
      "Chief Manager",
      "Senior Manager",
      "Manager",
      "Accountant",
      "Assistant",
    ],
  },
  {
    path: "/evaluation/view-data",
    component: ViewEvaluationData,
    roles: [
      "Chairman",
      "Secretary",
      "Admin",
      "HR",
      "MIS",
      "AGM",
      "Chief Manager",
      "Senior Manager",
      "Manager",
      "Accountant",
      "Assistant",
    ],
  },
  {
    path: "/evaluation/view-all",
    component: ViewEvaluationAll,
    roles: ["Chairman"],
  },
  {
    path: "/evaluation/form1",
    component: EvaluationForm1,
    roles: [
      "Chairman",
      "AGM",
      "Chief Manager",
      "Senior Manager",
      "Manager",
      "Assistant",
    ],
  },
  {
    path: "/evaluation/form2",
    component: EvaluationForm2,
    roles: [
      "Chairman",
      "AGM",
      "Chief Manager",
      "Senior Manager",
      "Manager",
      "Assistant",
    ],
  },
  {
    path: "/evaluation/form3",
    component: EvaluationForm3,
    roles: ["Chairman", "AGM", "Chief Manager", "Senior Manager", "Assistant"],
  },
];

export const reportRoutes = [
  {
    path: "/report",
    component: Report,
    roles: [
      "Chairman",
      "MIS",
      "Secretary",
      "Admin",
      "HR",
      "Accountant",
      "Assistant",
    ],
  },
  {
    path: "/report/collection-view",
    component: ViewReport,
    roles: [
      "Chairman",
      "MIS",
      "Secretary",
      "Admin",
      "HR",
      "Accountant",
      "Assistant",
    ],
  },
];

import React from 'react'
import Navigation from './Navigation'
import { useNavigate } from 'react-router-dom'

function Unauthorized() {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

  return (
    <div className='d-flex poisition-relative'>
        <div className='col-2 vh-100 position-fixed'>
            <Navigation />
        </div>
        {/* Header and Search Bar */}
        <div className='col-10 position-absolute top-0 end-0'>
            <div className='vh-100 d-flex justify-content-center align-items-center'>
                <div>
                    <div>
                        <img src="/assets/icon-unauthorized.png" alt="icon" className='w-25'/>
                    </div>
                    <div className='fs-4 fw-bold'>Unauthorized!</div>
                    <div className='fs-6 fw-light'>You do not have access to the requested Page. <br /> Please contact Admin for further support.</div>
                    <div className='mt-4'>
                        <button className='p-2 px-5 bg-info-subtle border-0 rounded-3 shadow-sm responsive-font-sm fw-semibold' onClick={goBack}>Go Back</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Unauthorized